// Third party packages
import useSWR from "swr";

// utils
import { axiosInstance } from "../app/service/axiosservice";

export const useGetSellerById = (sellerId) => {
  const endpoint = `seller/${sellerId}`;
  return useSWR(sellerId ? endpoint : null, async () => {
    try {
      const { data } = await axiosInstance.get(endpoint);
      return data?.data || {};
    } catch(error) {
      return {};
    }
  });
}// End of useGetSellerById

export const useGetBDMById = (bdmId) => {
  const endpoint = `bdm/detail/${bdmId}`;
   return useSWR(bdmId ? endpoint : null, async () => {
    try {
      const { data } = await axiosInstance.get(endpoint);
      return data?.data || {};
    } catch(error) {
      return {};
    }
  });
}// End of useGetBDMById