import { lazy, useEffect, useState } from 'react';

// Third party packages
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

// services
import { createPaymentIntent } from '../../services/onboarding.service';
import PaymentCard from '../payment-card';
import ElevatedButton from '../buttons.component';
import { axiosInstance } from '../../../../app/service/axiosservice';
// components
const ProfileAccordion = lazy(() => import('./profile-accordion'));
const ManageSubscriptionAccordion = lazy(() =>
  import('../manage-subscriptions/manage-subscription-accordion')
);
const CheckoutForm = lazy(() => import('../checkout-form'));

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const OnboardingAndVerification = props => {
  const {
    isSeller,
    isSubscriptionLoading,
    isSellerMissedDataToFillDuringStripeVerification,
    isStripeVerified,
    isSellerHaveTradewindsPlatformAccess,
    canIBypassStripeVerification,
    onActivePressed,
    customerId,
    isSellerTakingPlanForFirstTime,
    sellerPurchasedSubscriptionPlan,
    startFreeTrialHandler,
    updateCardDetails,
    upgradeSubscriptionHandler,
    cancelSubscriptionHandler,
    refetchSellerDataHandler
  } = props;

  const [loaderData, setLoaderData] = useState({
    isLoading: false,
    title: '',
    content: ''
  });
  const [paymentClientId, setPaymentClientId] = useState('');

  const { t } = useTranslation(['seller_onboarding']);
  const isPaymentFailed = sellerPurchasedSubscriptionPlan?.is_payment_failed || false;

  const setLoaderHandler = (isLoading, title, content) => {
    setLoaderData({
      isLoading: isLoading || false,
      title: title || '',
      content: content || ''
    });
  }; // End of setLoaderOff

  const purchaseSubscriptionPlanHandler = async () => {
    if (loaderData.isLoading) {
      return;
    }

    try {
      setLoaderHandler(true);
      const clientSecret = await createPaymentIntent({
        customer_id: customerId
      });

      setPaymentClientId(clientSecret);
    } catch (error) {
    } finally {
      setLoaderHandler(false);
    }
  }; // End of purchaseSubscriptionPlanHandler

  const showPaymentOption =
    sellerPurchasedSubscriptionPlan?.is_trial_ended &&
    (!sellerPurchasedSubscriptionPlan?.is_subscription_plan || isPaymentFailed);

  useEffect(() => {
    // This means trail ended. Getting payment intent
    if (showPaymentOption) {
      purchaseSubscriptionPlanHandler();
    }
  }, [sellerPurchasedSubscriptionPlan?.is_trial_ended]);

  const errorOrCorrectIcon =
    isStripeVerified || isSellerMissedDataToFillDuringStripeVerification ? (
      <CheckCircleIcon className='tw-text-green-600 tw-w-7' />
    ) : (
      <ExclamationCircleIcon className='tw-text-red-600 tw-w-7' />
    );

  const canIAccessMyCardDetails = sellerPurchasedSubscriptionPlan?.is_trial_ended;

  const canIAccessMyCardDetailsIcon = canIAccessMyCardDetails ? (
    <CheckCircleIcon className='tw-text-green-600 tw-w-7' />
  ) : (
    <ExclamationCircleIcon className='tw-text-red-600 tw-w-7' />
  );

  let showActivateButton = !isSeller && !isStripeVerified;
  if (isSeller) {
    showActivateButton = isSellerMissedDataToFillDuringStripeVerification
      ? false
      : !isStripeVerified;
  }
  const [currencyDetails, setCurrencyDetails] = useState();
  useEffect(() => {
    const getCurrencyAndSymbol = async () => {
      try {
        const getDtata = await axiosInstance.get(`new-sub-private/prices`);

        setCurrencyDetails(getDtata.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCurrencyAndSymbol();
  }, []);
  return (
    <ProfileAccordion
      title={
        <div className='tw-flex tw-items-center tw-space-x-4'>
          <p className='tw-text-xs tw-font-semibold md:tw-text-xl'>
            1. {t('onboarding_and_verification')}
          </p>
          <img
            src='/images/powered_by_stripe.svg'
            alt='powered by stripe'
            className='tw-w-16 tw-h-8 md:tw-w-[153px] md:tw-h-10'
          />
        </div>
      }
      isVerified={isSellerHaveTradewindsPlatformAccess}
      defaultOpen={!isStripeVerified || !isSellerHaveTradewindsPlatformAccess}
    >
      {/* Enrollment & Authentication */}
      <div className='tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center sm:tw-justify-between tw-pr-3 tw-border-b md:tw-pb-6'>
        <div className='tw-flex tw-items-center tw-justify-between'>
          <p className='tw-text-xs md:tw-text-lg tw-font-semibold tw-text-[#575858]'>
            {t('enrollment _and_authentication')}
            <span className='tw-text-red-700'>*</span>
          </p>
          <span className='sm:tw-hidden'>{errorOrCorrectIcon}</span>
        </div>

        <div className='tw-flex tw-items-center tw-space-x-2'>
          {showActivateButton && (
            <button
              onClick={onActivePressed}
              className='tw-w-[200px] tw-h-[28px] tw-border tw-border-[#575858] tw-rounded-md tw-text-[15px] tw-px-4 tw-mt-3 tw-animate-bounce hover:tw-animate-none'
            >
              {isSellerMissedDataToFillDuringStripeVerification
                ? t('complete_verification')
                : t('activate')}
            </button>
          )}

          <span className='tw-hidden sm:tw-block'>{errorOrCorrectIcon}</span>
        </div>
      </div>

      {/* Manage Subscriptions */}
      {isSeller && (
        <ManageSubscriptionAccordion
          isStripeVerified={
            isPaymentFailed
              ? false
              : isStripeVerified ||
                isSellerMissedDataToFillDuringStripeVerification ||
                canIBypassStripeVerification
          }
          isSubscriptionLoading={isSubscriptionLoading}
          sellerPurchasedSubscriptionPlan={sellerPurchasedSubscriptionPlan}
          customerId={customerId}
          isSellerTakingPlanForFirstTime={isSellerTakingPlanForFirstTime}
          startFreeTrialHandler={startFreeTrialHandler}
          refetchSellerDataHandler={refetchSellerDataHandler}
          upgradeSubscriptionHandler={upgradeSubscriptionHandler}
          cancelSubscriptionHandler={cancelSubscriptionHandler}
        />
      )}

      {/* Card Details */}
      {isSeller && (
        <div
          className={`tw-flex tw-flex-col tw-mt-3 sm:tw-mt-6 sm:tw-pr-3 tw-pb-10 tw-border-t tw-border-[#DCDBDB] tw-pt-4 ${
            canIAccessMyCardDetails ? '' : 'tw-opacity-50 tw-cursor-not-allowed'
          }`}
        >
          <div className='tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center sm:tw-justify-between'>
            <div className='tw-flex tw-items-center tw-justify-between'>
              <p className='tw-text-xs md:tw-text-lg tw-font-semibold tw-text-[#575858]'>
                {t('card_details')} <span className='tw-text-red-700'>*</span>
              </p>
              <span className='sm:tw-hidden tw-pr-3'>{canIAccessMyCardDetailsIcon}</span>
            </div>

            <div
              className={`tw-flex tw-items-center tw-space-x-2 ${
                canIAccessMyCardDetails ? '' : 'tw-cursor-not-allowed'
              }`}
            >
              {!showPaymentOption && (
                <button
                  onClick={canIAccessMyCardDetails ? updateCardDetails : null}
                  className={`tw-h-[28px] tw-border tw-rounded-md tw-text-xs md:tw-text-[15px] tw-px-4 ${
                    canIAccessMyCardDetails ? '' : '!tw-cursor-not-allowed'
                  }`}
                >
                  {t('update_my_card_details')}
                </button>
              )}

              <span className='tw-hidden sm:tw-block tw-pr-3'>
                {canIAccessMyCardDetailsIcon}
              </span>
            </div>
          </div>

          {showPaymentOption && (
            <div className='tw-bg-[#F1F2F6] tw-rounded-md tw-mt-5 tw-mr-5 sm:tw-mr-16 tw-pt-5 tw-pb-10'>
              <div className='tw-flex tw-flex-col tw-items-center tw-space-y-1 tw-px-4 sm:tw-space-y-0'>
                <p className='sm:tw-text-2xl md:tw-text-[44.45px] md:tw-leading-[54.18px] tw-font-semibold tw-text-center tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-[#37B34A] tw-via-[#33A7DF] tw-to-[#044E86]'>
                  {t('hello')}
                </p>
                <p className='sm:tw-text-lg tw-text-[#044E86]'>
                  {t('your_5_days_free_trial_has_ended')}.
                </p>
                <p className='sm:tw-text-lg tw-text-[#044E86]'>
                  {t('please_enter_your_credit_card')}.
                </p>
                <p className='sm:tw-text-2xl md:tw-text-[44.45px] md:tw-leading-[54.18px] tw-font-semibold tw-text-center tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-[#37B34A] tw-via-[#33A7DF] tw-to-[#044E86]'>
                  {t('30_days_for_10_dollar')} {currencyDetails?.currency}
                  {currencyDetails?.promotional}
                </p>
                <p className='tw-text-xs tw-text-[#044E86]'>
                  {t('after_3_months_you_will_be_billed')} {currencyDetails?.currency}
                  {currencyDetails?.monthly} {t('per. mo')}
                </p>
              </div>

              {/* Show re payment Links */}
              {sellerPurchasedSubscriptionPlan?.required_action &&
                sellerPurchasedSubscriptionPlan?.re_payment_link && (
                  <div className='tw-flex tw-justify-center tw-mt-8'>
                    <div className='tw-w-full sm:tw-w-96 tw-shadow-md tw-bg-white tw-p-5 tw-rounded-md'>
                      <h5 className='tw-text-center tw-font-semibold'>
                        Additional Action is required
                      </h5>
                      <p className='tw-text-center tw-font-semibold tw-mb-8 tw-text-red-500'>
                        You have a pending payment action on your end. Please review your
                        email or click the below button to initiate the payment process.
                      </p>
                      <a
                        href={sellerPurchasedSubscriptionPlan?.re_payment_link}
                        target='_blank'
                        rel='noreferrer'
                        className='tw-no-underline'
                      >
                        <ElevatedButton className='tw-w-full' variant='success'>
                          Complete Payment
                        </ElevatedButton>
                      </a>
                    </div>
                  </div>
                )}

              {sellerPurchasedSubscriptionPlan?.required_payment_method &&
                sellerPurchasedSubscriptionPlan?.pi_client_secret && (
                  <div className='tw-flex tw-justify-center tw-mt-8'>
                    <Elements stripe={stripePromise}>
                      <PaymentCard
                        clientSecret={sellerPurchasedSubscriptionPlan?.pi_client_secret}
                      />
                    </Elements>
                  </div>
                )}

              {/*  */}
              {!sellerPurchasedSubscriptionPlan?.required_payment_method &&
                !sellerPurchasedSubscriptionPlan?.required_action &&
                paymentClientId && (
                  <div className='tw-flex tw-justify-center tw-mt-8'>
                    <Elements
                      stripe={stripePromise}
                      options={{ clientSecret: paymentClientId }}
                    >
                      <CheckoutForm />
                    </Elements>
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </ProfileAccordion>
  );
}; // End of ProfileAccordion

export default OnboardingAndVerification;
