import fetch, { axiosInstance } from '../../../app/service/axiosservice';
import _ from 'lodash';
// const createUser = variables => {
//   let returnResp = {};
//   const params = {
//     ...variables
//   };

//   return fetch(`api/v1/user`, {
//     method: 'post',
//     body: params
//   })
//     .then(response => {
//       console.log('response after signup', response);
//       if (response.status === 200) {
//         returnResp = {
//           success: true,
//           message: 'Seller created successfully!!!',
//           userId: response?.response?.id,
//           status: response?.response?.status
//         };
//       } else {
//         returnResp = {
//           success: false,
//           message: response.isDuplicateEmail
//             ? response?.message
//             : 'Some error occured while creating seller'
//         };
//       }
//       return returnResp;
//     })
//     .catch(err => {
//       console.log('Error', err);
//       returnResp = {
//         success: false,
//         message: 'Some error occured while creating seller'
//       };
//       return returnResp;
//     });
// };
const createUser = variables => {
  let returnResp = {};
  const params = {
    ...variables
  };
  let checkIsBdaReg = localStorage.getItem('isBdaReg');
  let url = Boolean(checkIsBdaReg) ? 'auth/bdm-signup' : 'auth/seller-signup';
  return fetch(
    url,
    {
      method: 'post',
      body: params
    },
    false,
    30000,
    true
  )
    .then(response => {
      console.log('response after signup', response);
      if (response.status === 200 || response.status === 201) {
        returnResp = {
          success: true,
          message: 'Seller created successfully!!!'
          // userId: response?.response?.id,
          // status: response?.response?.status
        };
      } else {
        returnResp = {
          success: false,
          message: response.isDuplicateEmail ? response?.message : response?.message
        };
      }
      return returnResp;
    })
    .catch(err => {
      console.log('Error', err);
      returnResp = {
        success: false,
        message: 'Some error occured while creating seller'
      };
      return returnResp;
    });
};

export const getSignupCountries = () => {
  let returnResp = {};

  return fetch(
    `cms/country/signup`,
    {
      method: 'get'
    },
    false,
    30000,
    true
  )
    .then(response => {
      if (response.status === 200) {
        returnResp = {
          success: true,
          message: 'ok',
          countries: response?.data
        };
      } else {
        returnResp = {
          success: false,
          message: 'Error in verification'
        };
      }
      return returnResp;
    })
    .catch(err => {
      returnResp = {
        success: false,
        message: 'Error in verification'
      };
      return returnResp;
    });
};

const getCountries = () => {
  let returnResp = {};

  return fetch(
    `cms/country?limit=500`,
    {
      method: 'get'
    },
    false,
    30000,
    true
  )
    .then(response => {
      if (response.status === 200) {
        returnResp = {
          success: true,
          message: 'ok',
          countries: response?.data
        };
      } else {
        returnResp = {
          success: false,
          message: 'Error in verification'
        };
      }
      return returnResp;
    })
    .catch(err => {
      returnResp = {
        success: false,
        message: 'Error in verification'
      };
      return returnResp;
    });
};

const getIndividualCountry = val => {
  let returnResp = {};

  return fetch(
    `cms/country/search?query=${val}`,
    {
      method: 'get'
    },
    false,
    30000,
    true
  )
    .then(response => {
      if (response.status === 200) {
        returnResp = {
          success: true,
          message: 'ok',
          country: response?.data
        };
      } else {
        returnResp = {
          success: false,
          message: 'Error in verification'
        };
      }
      return returnResp;
    })
    .catch(err => {
      returnResp = {
        success: false,
        message: 'Error in verification'
      };
      return returnResp;
    });
};
const resendEmail = (emailId, loginAs = '') => {
  let returnResp = {};

  return fetch(
    `auth/email-resend/${emailId}?login_as=${loginAs}`,
    {
      method: 'get'
    },
    false,
    30000,
    true
  )
    .then(response => {
      console.log('response after signup', response);
      if (response.status === 200) {
        returnResp = {
          success: true,
          message: 'Email sent successfully!!!'
        };
      } else {
        returnResp = {
          success: false,
          message: 'Some error occured while sending email'
        };
      }
      return returnResp;
    })
    .catch(err => {
      console.log('Error', err);
      returnResp = {
        success: false,
        message: 'Some error occured while sending email'
      };
      return returnResp;
    });
};

const registerSellerInfo = variables => {
  let returnResp = {};
  const params = {
    ...variables
  };

  //console.log('IN Resiter', params);
  return fetch(`api/v1/seller`, {
    method: 'post',
    body: params
  })
    .then(response => {
      // console.log('response', response);
      if (response.status === 200) {
        returnResp = {
          success: true,
          message: 'Seller Information saved successfully!!!',
          sellerId: response?.response?.id
        };
      } else {
        returnResp = {
          success: false,
          message: 'Some error occured while saving seller information'
        };
      }
      return returnResp;
    })
    .catch(err => {
      returnResp = {
        success: false,
        message: 'Some error occured while saving seller information'
      };
      return returnResp;
    });
};

const chooseSellerPlan = variables => {
  let returnResp = {};
  const params = {
    ...variables
  };
  console.log('IN Plan', _.flatten(variables));
  console.log('IN Plan', params);

  return fetch(`api/v1/seller`, {
    method: 'patch',
    body: params
  })
    .then(response => {
      console.log('response', response);
      if (response.status === 200) {
        returnResp = {
          success: true,
          message: 'Selected seller plan saved successfully!!!'
        };
      } else {
        returnResp = {
          success: false,
          message: 'Some error occured while saving seller plan'
        };
      }
      return returnResp;
    })
    .catch(err => {
      console.log('Error', err);
      returnResp = {
        success: false,
        message: 'Some error occured while saving seller plan'
      };
      return returnResp;
    });
};

const searchBdm = params => {
  let returnResp = {};
  return fetch(
    `bdm/${params.name}`,
    {
      method: 'get'
    },
    false,
    30000,
    true
  )
    .then(response => {
      if (response.status === 200) {
        returnResp = {
          success: true,
          message: 'ok',
          response: response?.data
        };
      } else {
        returnResp = {
          success: false,
          message: 'Error in Fetching BDM'
        };
      }
      return returnResp;
    })
    .catch(err => {
      returnResp = {
        success: false,
        message: 'Error in Fetching BDM'
      };
      return returnResp;
    });
};

export {
  chooseSellerPlan,
  createUser,
  registerSellerInfo,
  resendEmail,
  getCountries,
  getIndividualCountry,
  searchBdm
};
