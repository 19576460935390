import { Fragment } from 'react';

// Third party packages
import { Dialog, Transition } from '@headlessui/react';

const LoaderPopup = props => {
  const { isOpen = false, title, message } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='tw-relative tw-z-10' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-40' />
        </Transition.Child>

        <div className='tw-fixed tw-inset-0 tw-overflow-y-auto'>
          <div className='tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='tw-w-full tw-flex tw-flex-col tw-items-center tw-max-w-md tw-transform tw-overflow-hidden tw-rounded-2xl tw-bg-white tw-p-6 tw-text-left tw-align-middle tw-shadow-xl tw-transition-all'>
                <Dialog.Title
                  as='h3'
                  className='tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900'
                >
                  {title || 'Wait for a moment...'}
                </Dialog.Title>
                <div className='tw-mt-2'>
                  <p className='tw-text-sm tw-text-gray-500'>
                    {message || 'We are getting your data...'}
                  </p>
                </div>

                <div
                  className='tw-mt-4 tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-current tw-border-r-transparent tw-align-[-0.125em] tw-motion-reduce:animate-[spin_1.5s_linear_infinite]'
                  role='status'
                >
                  <span className='!tw-absolute !tw--m-px !tw-h-px !tw-w-px !tw-overflow-hidden !tw-whitespace-nowrap !tw-border-0 !tw-p-0 !tw-[clip:rect(0,0,0,0)]'></span>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoaderPopup;
