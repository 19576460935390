// import React, { useState, createContext } from 'react';
// import { ReactNotifications } from 'react-notifications-component';

import { createContext, useState } from 'react';

// import AppNavbar from '../views/components/navbar/AppNavbar';
// import AppSidebar from '../views/components/sidebar/AppSidebar';
// import Loader from 'react-loader-spinner';
// import PrivateRoute from './PrivateRoute';
// import './route.scss';
// import { useSellerStore } from '../store/seller.store';
// import { useNavigate } from 'react-router-dom';
// import Footer from '../views/components/footer/footer';

export const LanguageContext = createContext();

export default function PrivateRouteApp({ children }) {
  //   const [showSidebar, setShowSidebar] = React.useState(true);
  //   const [showBuyerSidebar, setShowBuyerSidebar] = React.useState(false);
  const [langauge, setLanguage] = useState('EN');
  //   const userType = localStorage.getItem('user-Type');
  const [loader, showLoader] = useState(false);
  //   const userIsAdmin = localStorage.getItem('userIsAdmin');
  const lang = { langauge, showLoader };

  //   const sellerId = localStorage.getItem('tw-seller_id');
  //   const bdmId = localStorage.getItem('bdm-id');
  //   const isSeller = sellerId !== '' && sellerId !== null && sellerId !== undefined;
  //   const isBDM = bdmId !== '' && bdmId !== null && bdmId !== undefined;

  //   const navigate = useNavigate();
  //   const isSellerHaveTradewindsPlatformAccess = useSellerStore(
  //     state => state.isSellerHaveTradewindsPlatformAccess
  //   );
  //   const isOnProfilePage = history?.location?.pathname?.toLowerCase() === '/app/profile';

  //   const handleResize = () => {
  //     if (window.innerWidth < 992) {
  //       setShowSidebar(false);
  //     }
  //   };

  //   const setlanguage = val => {
  //     setLanguage(val);
  //   };

  //   React.useEffect(() => {
  //     handleResize();
  //     window.addEventListener('resize', handleResize);
  //     return () => window.removeEventListener('resize', handleResize);
  //   }, []);

  return (
    <LanguageContext.Provider value={lang}>
      {/* //       <div
//         className='h-100 overflow-hidden'
//         onContextMenu={
//           isSeller && !isSellerHaveTradewindsPlatformAccess
//             ? event => event.preventDefault()
//             : null
//         }
//       >
//         {loader ? (
//           <div className='api-loader'>
//             <Loader
//               type='TailSpin'
//               color='#0d6efd'
//               height={100}
//               width={100}
//               className='spinner'
//             />
//           </div>
//         ) : null}
//         <AppNavbar
//           showSidebar={showSidebar}
//           setShowSidebar={setShowSidebar}
//           userIsAdmin={userIsAdmin}
//           setSelectedLanguage={evt => setlanguage(evt)}
//           showBuyerSidebar={showBuyerSidebar}
//           setShowBuyerSidebar={setShowBuyerSidebar}
//         />

//         <div className='tw-bg-[#F1F2F6] tw-h-full tw-overflow-x-hidden tw-overflow-y-auto'>
//           <ReactNotifications />

//           <div className='tw-flex'>
//             <div>
//               <AppSidebar
//                 showSidebar={showSidebar}
//                 setShowSidebar={setShowSidebar}
//                 userIsAdmin={userIsAdmin}
//                 showBuyerSidebar={showBuyerSidebar}
//                 userType={userType}
//               />
//             </div>

//             <div className='tw-relative tw-w-full tw-pt-16 tw-overflow-auto'>
//               <PrivateRoute userIsAdmin={userIsAdmin} />

//               {!isOnProfilePage &&
//                 (isSeller || isBDM) &&
//                 !isSellerHaveTradewindsPlatformAccess && (
//                   <div
//                     className='tw-bg-white tw-opacity-80 tw-absolute tw-inset-0 tw-cursor-not-allowed'
//                     title='Please completed your profile to unlock this page.'
//                     onClick={event => event.stopPropagation()}
//                   ></div>
//                 )}
//             </div>
//           </div>

//           <Footer />
//         </div>
//       </div> */}

      {children}
    </LanguageContext.Provider>
  );
}
