import React from 'react'

const ElevatedButton = (props) => {

    const { type, onClick, variant, className = '', disabled, children } = props;

    const baseButtonClassName = "tw-flex tw-items-center tw-justify-center tw-text-white tw-text-sm tw-font-semibold tw-h-10 tw-px-4 tw-rounded tw-shadow-lg";

    let buttonVariantClassName = "tw-bg-[#044E86]/90 hover:tw-bg-[#044E86]";
    if (variant === "success") {
        buttonVariantClassName = "tw-bg-[#166b22]/90 hover:tw-bg-[#166b22]";
    } else if (variant === "danger") {
        buttonVariantClassName = "tw-bg-red-700 hover:tw-bg-red-800";
    }

  return (
    <button 
        type={type || "button"}
        onClick={onClick}
        disabled={disabled}
        className={`${baseButtonClassName} ${buttonVariantClassName} ${className}`}
        >
            {children}
        </button>
    )
}

export default ElevatedButton