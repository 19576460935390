import { useEffect, useState } from 'react';

// Third party packages
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import useSWR from 'swr';

// components
import { ProfileBanner, SellerInfo } from './components';
import OnboardingAndVerification from './components/profile-accordions/onboarding-and-verification';
import StoreManagementAccordion from './components/profile-accordions/store-management';
import ProfileAccordion from './components/profile-accordions/profile-accordion';
import LoaderPopup from './components/loader-poup';
import VideoPreviewModal from '../../components/elements/video-preview-modal';

// helper
import { axiosInstance } from '../../app/service/axiosservice';

// Hooks
import { useGetBDMById, useGetSellerById } from '../../hooks/use-seller.hooks';

// services
import {
  createPaymentIntent,
  createStripeAccountBySellerOrBDMId
} from './services/onboarding.service';
import { useSellerStore } from '../../store/seller.store';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const NewSellerProfile = () => {
  const language = localStorage.getItem('i18nextLng');
  const sellerId = localStorage.getItem('tw-seller_id');
  const bdmId = localStorage.getItem('bdm-id');

  const { t } = useTranslation(['seller_onboarding']);

  const [loaderData, setLoaderData] = useState({
    isLoading: false,
    title: '',
    content: ''
  });
  const [paymentClientId, setPaymentClientId] = useState('');
  const [isStoreManagementCompleted, setIsStoreManagementCompleted] = useState(false);

  const setIsSellerHaveTradewindsPlatformAccess = useSellerStore(
    state => state.setIsSellerHaveTradewindsPlatformAccess
  );

  const searchParams = new URLSearchParams(window?.location?.search);
  const setupIntent = searchParams.get('setup_intent');
  const setupIntentClientSecret = searchParams.get('setup_intent_client_secret');
  const redirectStatus = searchParams.get('redirect_status');

  const setLoaderHandler = (isLoading, title, content) => {
    setLoaderData({
      isLoading: isLoading || false,
      title: title || '',
      content: content || ''
    });
  }; // End of setLoaderOff

  // Creating subscription after submitting card details
  useEffect(() => {
    if (
      setupIntent &&
      setupIntentClientSecret &&
      redirectStatus === 'succeeded' &&
      !loaderData.isLoading
    ) {
      setLoaderHandler(
        true,
        'We are fetching your subscription details, It will take a few second.'
      );
      axiosInstance.get(`new-sub-private/paid-subscription`).finally(() => {
        /**
         * I am using this because even after redirecting using history.replace hash is coming after query string
         * and query string is also not getting removed.
         */

        setTimeout(() => {
          setLoaderHandler(false);
          // mutate({});
          subscriptionMutate({});
          window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/profile`;
          // navigate('/profile');
        }, 1500);
      });
    }
  }, [setupIntent, setupIntentClientSecret, redirectStatus]);

  const isSeller = sellerId !== '' && sellerId !== null && sellerId !== undefined;

  //get seller by id
  const {
    data: sellerData,
    isLoading: isSellerDataFetching,
    mutate: sellerMutate
  } = useGetSellerById(sellerId);

  // Fetching seller subscription
  const {
    data: subscriptionRes,
    isLoading: isSubscriptionLoading,
    isValidating: isSubscriptionValidating,
    mutate: subscriptionMutate
  } = useSWR(`/seller/subscription/${sellerId}`, url =>
    sellerId ? axiosInstance.get(url) : null
  );
  const sellerSubscriptionData = subscriptionRes?.data?.data || {};
  console.log('subscriptionData--subscriptionData ', {
    isSubscriptionLoading,
    isSubscriptionValidating
  });

  const { data: bdmData, isLoading: isBdmDataFetching, bdmMutate } = useGetBDMById(bdmId);

  const data = (isSeller ? sellerData : bdmData) || {};
  if (!isSeller) {
    data.stripe_status = data?.status_stripe || '';
  }
  const userData = data?.edges?.user || {};
  const isDataFetching = (isSeller ? isSellerDataFetching : isBdmDataFetching) || false;
  const mutate = isSeller ? sellerMutate : bdmMutate;

  /***
   * # Step first is stripe verification.
   * # Step second is to take subscription.
   * # Step third is to complete or fill necessary data inside store management.
   *
   *
   * # Stripe Status
   *  - restricted (Seller just created a stripe account and not started verification process).
   *  - enabled (Seller created a stripe account and missed some data to fill or upload document during verification).
   *  - completed (Seller successfully completed stripe verification)
   */
  const stripeStatus = (data?.stripe_status || '')?.toLowerCase();
  const isSellerMissedDataToFillDuringStripeVerification = stripeStatus === 'enabled';
  const isStripeVerified = stripeStatus === 'completed';

  // Step second - Manage Subscription
  // const sellerPurchasedSubscriptionPlan =
  //   sellerSubscriptionData || data?.edges?.subscriptions || null;
  const sellerPurchasedSubscriptionPlan = sellerSubscriptionData || {};
  const isPaymentFailed = sellerPurchasedSubscriptionPlan?.is_payment_failed || false;
  const isSellerTakingPlanForFirstTime =
    !data?.is_card &&
    !data?.is_trial &&
    !sellerPurchasedSubscriptionPlan?.is_subscription_plan;

  if (sellerPurchasedSubscriptionPlan) {
    const sellerSubscriptionSellerData = sellerSubscriptionData?.edges?.seller || {};

    const isTrialPlan =
      sellerSubscriptionSellerData?.is_trial &&
      sellerPurchasedSubscriptionPlan?.subscription_status === 'trialing';

    sellerPurchasedSubscriptionPlan.is_card = sellerSubscriptionSellerData?.is_card;
    sellerPurchasedSubscriptionPlan.is_trial = isTrialPlan;
    sellerPurchasedSubscriptionPlan.is_trial_ended =
      sellerSubscriptionSellerData?.is_trial_ended;
  }
  const canIBypassStripeVerification = data?.bypass_stripe_verification || false;

  let isSellerHaveTradewindsPlatformAccess =
    isStripeVerified || canIBypassStripeVerification;

  // Giving seller access to Tradewinds Platform Access
  if (isSeller) {
    // If seller trial is ended and payment is failed. Then seller will loose access to tradewinds platform
    if (canIBypassStripeVerification) {
      isSellerHaveTradewindsPlatformAccess = true;
    } else if (!sellerPurchasedSubscriptionPlan?.is_trial_ended && isPaymentFailed) {
      isSellerHaveTradewindsPlatformAccess = false;
    } else {
      const isStripeBasicOrFullVerificationDone =
        isStripeVerified || isSellerMissedDataToFillDuringStripeVerification;
      const isSellerHaveSubscriptionPlan =
        sellerPurchasedSubscriptionPlan?.is_subscription_plan || false;
      isSellerHaveTradewindsPlatformAccess =
        sellerPurchasedSubscriptionPlan.is_trial ||
        (isStripeBasicOrFullVerificationDone && isSellerHaveSubscriptionPlan);
    }
  }

  // Completed First Step because seller email will be verified otherwise seller will not able to login.
  let completedStep = 1;
  if (!canIBypassStripeVerification && isSellerHaveTradewindsPlatformAccess) {
    completedStep = 2;
  }
  if (
    !canIBypassStripeVerification &&
    isSellerHaveTradewindsPlatformAccess &&
    isStoreManagementCompleted
  ) {
    completedStep = 3;
  }

  useEffect(() => {
    setIsSellerHaveTradewindsPlatformAccess(isSellerHaveTradewindsPlatformAccess);
    localStorage.setItem(
      'isSellerHaveTradewindsPlatformAccess',
      isSellerHaveTradewindsPlatformAccess
    );
  }, [isSellerHaveTradewindsPlatformAccess]);

  const getStripeAccountUrl = async () => {
    if (loaderData.isLoading) {
      return;
    }

    try {
      setLoaderHandler(true);
      const stripeAccountCreationUrl = await createStripeAccountBySellerOrBDMId(
        isSeller ? sellerId : bdmId,
        isSeller
      );
      setLoaderHandler(false);
      window.open(stripeAccountCreationUrl, '_self');
    } catch (error) {
      setLoaderHandler(false);
    } finally {
      setLoaderHandler(false);
    }
  }; // End of getStripeAccountUrl

  const startFreeTrialHandler = async () => {
    if (loaderData.isLoading) {
      return;
    }
    try {
      setLoaderHandler(true, 'Wait your trail account is creating ...');
      const { data } = await axiosInstance.get(`new-sub-private/free-trail`);
      console.log('[startFreeTrialHandler] =', data);
      // mutate({});
      subscriptionMutate({});
      toast.success(data?.message || '');
    } catch (error) {
      toast.success(error?.response?.data?.message || '');
    } finally {
      setLoaderHandler(false);
    }
  }; // End of startFreeTrialHandler

  const purchaseSubscriptionPlanHandler = async priceId => {
    if (loaderData.isLoading) {
      return;
    }

    const customerId = data?.customer_id;
    if (!priceId) {
      alert('Price Id not found');
      return;
    } else if (!customerId) {
      alert('Customer Id not found');
      return;
    }

    try {
      setLoaderHandler(true);
      const clientSecret = await createPaymentIntent({
        price_id: priceId,
        customer_id: customerId
      });

      setPaymentClientId(clientSecret);
    } catch (error) {
    } finally {
      setLoaderHandler(false);
    }
  }; // End of purchaseSubscriptionPlanHandler

  const updateBankOrCardDetails = async () => {
    if (loaderData.isLoading) {
      return;
    }

    try {
      setLoaderHandler(true);
      const { data } = await axiosInstance.get('stripe/seller-login-link');
      const url = data?.data?.login_link || '';
      if (url) {
        window.open(url, '_self')?.focus();
      }
    } catch (error) {
    } finally {
      setLoaderHandler(false);
    }
  }; // End of updateBankOrCardDetails

  const upgradeSubscriptionHandler = async newPriceId => {
    if (loaderData.isLoading) {
      return;
    }

    if (!newPriceId) {
      alert('Price Id not found');
      return;
    } else if (!sellerId) {
      alert('Seller Id not found');
      return;
    }

    try {
      setLoaderHandler(true);
      const { data } = await axiosInstance.post('subscription/upgrade-subscription', {
        new_price_id: newPriceId,
        seller_id: sellerId
      });
      console.log('[upgradeSubscriptionHandler] =', data);
      mutate({});
    } catch (error) {
    } finally {
      setLoaderHandler(false);
    }
  }; // End of upgradeSubscriptionHandler

  const cancelSubscriptionHandler = async () => {
    if (loaderData.isLoading) {
      return;
    }

    try {
      setLoaderHandler(true);
      const { data } = await axiosInstance.get(
        `subscription/cancel-subscription/${sellerId}`
      );
      console.log('[cancelSubscriptionHandler] =', data);
      mutate({});
    } catch (error) {
    } finally {
      setLoaderHandler(false);
    }
  }; // End of cancelSubscriptionHandler

  const updateSellerOrBDMInfoHandler = async data => {
    try {
      if (isSeller) {
        await axiosInstance.patch('seller/business-info', {
          seller_id: sellerId,
          website: data?.website,
          phone_number: data?.phone_number,
          company_details: {
            business_name: data?.business_name,
            website: data?.website
          }
        });
      } else {
        await axiosInstance.post(`bdm/bdm-company-info/${bdmId}`, {
          business_name: data?.business_name,
          website: data?.website,
          phone_number: +data?.phone_number
        });
      }
      toast.success('Updated Successfully');
      mutate?.({});
    } catch (error) {
      toast.success(error?.response?.data?.message || 'Something went wrong');
    }
  }; // End of updateSellerOrBDMInfoHandler

  const [currencyDetails, setCurrencyDetails] = useState();
  useEffect(() => {
    const getCurrencyAndSymbol = async () => {
      setLoaderData(true);
      try {
        const getDtata = await axiosInstance.get(`new-sub-private/prices`);
        console.log('currencyDetailscurrencyDetails', getDtata);
        setCurrencyDetails(getDtata.data.data);
        setLoaderData(false);
      } catch (error) {
        console.log(error);
      }
    };
    getCurrencyAndSymbol();
  }, []);
  return (
    <>
      <LoaderPopup
        isOpen={loaderData.isLoading}
        message={
          loaderData.isLoading
            ? loaderData.title ||
              loaderData.content ||
              `${t(
                'now_we_are_going_to_do_account_verification_to_onboard_you_successfully'
              )}.`
            : ''
        }
      />

      <ToastContainer />

      <VideoPreviewModal iframeUrl='https://www.youtube.com/embed/Yc7u1eNkvoM' />

      <div className='tw-px-2 lg:tw-px-8 tw-pt-4 tw-pb-8 tw-space-y-5'>
        {/* <DragNDrop /> */}

        {isDataFetching ? (
          <Skeleton baseColor='#DEDFE0' className='!tw-block tw-h-[480px] tw-shadow' />
        ) : (
          <div className='tw-bg-white tw-rounded-md tw-shadow-sm'>
            {/* Banner */}
            <ProfileBanner
              currencyDetails={currencyDetails}
              key={isSubscriptionLoading}
              isSeller={isSeller}
              bannerImageUrl={data?.store_front?.store_banner}
              logoUrl={data?.store_front?.store_logo}
              isStripeVerified={isStripeVerified}
              sellerPurchasedSubscriptionPlan={sellerPurchasedSubscriptionPlan}
              isSellerMissedDataToFillDuringStripeVerification={
                isSellerMissedDataToFillDuringStripeVerification
              }
              canIBypassStripeVerification={canIBypassStripeVerification}
              isSellerHaveTradewindsPlatformAccess={isSellerHaveTradewindsPlatformAccess}
              completedStep={completedStep}
              isSubscriptionLoading={isSubscriptionLoading}
              paymentFailedTitle={
                isPaymentFailed
                  ? 'Your 5 Day Free Trial Has Ended, Please Review Your Credit Card Details as your payment failed last time'
                  : ''
              }
              paymentFailedReason={
                sellerPurchasedSubscriptionPlan?.payment_failed_reason
                  ? `Reason: ${
                      sellerPurchasedSubscriptionPlan?.payment_failed_reason || ''
                    }`
                  : ''
              }
              incompleteStripeVerificationErrorMessage={(data?.disabled_reason?.[0]
                ? `${t('reason')}: ${data?.disabled_reason?.[0]}`
                : !isSeller && isSellerMissedDataToFillDuringStripeVerification
                ? t('you_have_missed_some_data_to_provide_during_verification')
                : ''
              )
                ?.replaceAll('_', ' ')
                ?.replaceAll('.', ' ')}
            />

            {/* Seller Info */}
            <div className='tw-px-4 lg:tw-px-8 tw-py-5'>
              <SellerInfo
                isSeller={isSeller}
                name={data?.name || ''}
                countryName={data?.edges?.country?.name?.[language] || ''}
                email={userData?.email || ''}
                businessName={data?.edges?.company?.business_name || ''}
                joinedDate={moment(data?.created_at).format('MMM Do YYYY') || ''}
                tradewindsEmail={userData?.tradewinds_email || ''}
                websiteUrl={data?.edges?.company?.website || ''}
                storeName={data?.store_name || ''}
                storeUrl={`${process.env.REACT_APP_SHOPPING_SITE_URL}/${
                  data?.store_slug || ''
                }`}
                phoneNumber={
                  data?.edges?.company?.phone_number || userData?.phone_number || ''
                }
                isSellerHaveTradewindsPlatformAccess={
                  isSellerHaveTradewindsPlatformAccess
                }
                updateSellerOrBDMInfoHandler={updateSellerOrBDMInfoHandler}
              />
            </div>
          </div>
        )}

        {/* Onboarding and verification */}
        {isDataFetching ? (
          <Skeleton
            baseColor='#DEDFE0'
            className='!tw-block tw-h-20 tw-shadow !tw-rounded-md'
          />
        ) : (
          <OnboardingAndVerification
            isSellerMissedDataToFillDuringStripeVerification={
              isSellerMissedDataToFillDuringStripeVerification
            }
            isStripeVerified={isStripeVerified}
            isSellerHaveTradewindsPlatformAccess={isSellerHaveTradewindsPlatformAccess}
            canIBypassStripeVerification={canIBypassStripeVerification}
            onActivePressed={getStripeAccountUrl}
            customerId={data?.customer_id}
            isSeller={isSeller}
            isSubscriptionLoading={isSubscriptionLoading}
            sellerPurchasedSubscriptionPlan={sellerPurchasedSubscriptionPlan}
            purchaseSubscriptionPlanHandler={purchaseSubscriptionPlanHandler}
            isSellerTakingPlanForFirstTime={isSellerTakingPlanForFirstTime}
            paymentClientId={paymentClientId}
            startFreeTrialHandler={startFreeTrialHandler}
            updateCardDetails={updateBankOrCardDetails}
            upgradeSubscriptionHandler={upgradeSubscriptionHandler}
            cancelSubscriptionHandler={cancelSubscriptionHandler}
            refetchSellerDataHandler={() => mutate({})}
          />
        )}

        {/* Store Management */}
        {isDataFetching ? (
          <Skeleton
            baseColor='#DEDFE0'
            className='!tw-block tw-h-20 tw-shadow !tw-rounded-md'
          />
        ) : (
          <StoreManagementAccordion
            key={isDataFetching}
            isSeller={isSeller}
            isStripeVerified={isStripeVerified}
            isSellerHaveTradewindsPlatformAccess={isSellerHaveTradewindsPlatformAccess}
            sellerData={data}
            setIsStoreManagementCompleted={setIsStoreManagementCompleted}
          />
        )}

        {/* Money Management */}
        {isDataFetching ? (
          <Skeleton
            baseColor='#DEDFE0'
            className='!tw-block tw-h-20 tw-shadow !tw-rounded-md'
          />
        ) : (
          <ProfileAccordion
            title={`3. ${t('money_management')}`}
            disabled={
              !isStripeVerified && !isSellerMissedDataToFillDuringStripeVerification
            }
            isVerified={
              isStripeVerified || isSellerMissedDataToFillDuringStripeVerification
            }
          >
            <div className='tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center sm:tw-justify-between tw-pr-3 tw-border-b tw-pb-6'>
              <div className='tw-flex tw-items-center tw-space-x-4'>
                <p className='tw-text-xs md:tw-text-lg tw-font-semibold tw-text-[#575858]'>
                  {t('bank_account_details')} <span className='tw-text-red-700'>*</span>
                </p>
                <img
                  src='/images/powered_by_stripe.svg'
                  alt='powered by stripe'
                  className='tw-w-16 tw-h-8 md:tw-w-[153px] md:tw-h-10'
                />
              </div>
              <div className='tw-flex tw-items-center tw-space-x-2'>
                <button
                  onClick={updateBankOrCardDetails}
                  className='tw-w-[127px]s tw-h-[28px] tw-border tw-rounded-md tw-text-[15px] tw-px-4'
                >
                  {t('update_my_bank_details')}
                </button>
                <CheckCircleIcon className='tw-text-green-600 tw-w-7 tw-hidden sm:tw-block' />
              </div>
            </div>
          </ProfileAccordion>
        )}
      </div>
    </>
  );
};

export default NewSellerProfile;

// --------
export const DragNDrop = () => {
  return (
    <div>
      <div className='tw-w-60 tw-h-20 tw-bg-purple-500 tw-flex tw-items-center tw-justify-center'>
        <DraggableItem className='tw-bg-white tw-px-2 tw-text-xs'>
          This div is draggable
        </DraggableItem>
      </div>
      <div className='tw-w-60 tw-h-20 tw-bg-purple-500 tw-mt-8'></div>
    </div>
  );
}; // End of DragNDrop

export const DraggableItem = ({ children, className = '', id = '' }) => {
  return (
    <div id={id} className={className} draggable={true}>
      {children}
    </div>
  );
}; // End of DraggableItem
