import { axiosInstance } from "../../../app/service/axiosservice";

export const createStripeAccountBySellerOrBDMId = async (id, isSeller) => {
    const url = isSeller ? `stripe/create-account/${id}` : `stripe/create-account-bdm/${id}`;

    try {
        const { data } = await axiosInstance.post(url);
        return data?.data?.account_url;
    } catch(error) {
        console.error(error);
    }
}// End of createStripeAccountBySellerOrBDMId


export const getSubscriptionPlans = async (sellerId) => {
    try {
        const { data } = await axiosInstance.get('subscription');
        console.log("[getSubscriptionPlans] =", data?.data);
        return data?.data;
        

    } catch(error) {
        console.error(error);
    }
}// End of getSubscriptionPlans


export const createPaymentIntent = async (payload = {
    price_id: "",
    customer_id: "",
}) => {
    try {
        const { data } = await axiosInstance.post('subscription/setup-intent', payload);
        console.log("[createPaymentIntent] =", data?.data);
        return data?.data?.payment_Client;
    } catch(error) {
        console.error(error);
    }
}// End of createPaymentIntent