import { create } from 'zustand';

export const useSellerStore = create((set) => ({
    isSellerHaveTradewindsPlatformAccess: false,
    setIsSellerHaveTradewindsPlatformAccess: (isSellerHaveTradewindsPlatformAccess) => set(() => ({ isSellerHaveTradewindsPlatformAccess:  isSellerHaveTradewindsPlatformAccess})),
    autoCheckForPlatformAccess: () => set(() => {
        const isSellerHaveTradewindsPlatformAccess  = localStorage.getItem("isSellerHaveTradewindsPlatformAccess");
        if(isSellerHaveTradewindsPlatformAccess === "true" || isSellerHaveTradewindsPlatformAccess === true) {
            return { isSellerHaveTradewindsPlatformAccess: true };
        }

        return { isSellerHaveTradewindsPlatformAccess: false };
    }),
}));