import React from 'react';

// Third party packages
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

const ProfileBanner = props => {
  const {
    currencyDetails,
    isSeller,
    isSubscriptionLoading,
    incompleteStripeVerificationErrorMessage,
    paymentFailedTitle,
    paymentFailedReason,
    bannerImageUrl,
    logoUrl,
    completedStep,
    isStripeVerified,
    sellerPurchasedSubscriptionPlan,
    isSellerMissedDataToFillDuringStripeVerification,
    isSellerHaveTradewindsPlatformAccess,
    canIBypassStripeVerification
  } = props;

  const { t } = useTranslation(['seller_onboarding']);

  const steps = [
    {
      title: '1',
      isActive: false
    },
    {
      title: '2',
      isActive: false
    },
    {
      title: '3',
      isActive: false
    }
  ]?.filter((step, index) => {
    if (completedStep >= index + 1) {
      step.isActive = true;
    }

    if (!isSeller && index <= 1) {
      return step;
    } else if (isSeller) {
      return step;
    }
    return step;
  });

  const infoOrErrorMessage = {
    isError: true,
    title: t('first_time_signing_in') + '?',
    message:
      incompleteStripeVerificationErrorMessage ||
      t('complete_your_onboarding_and_verification')
  };

  if (paymentFailedReason) {
    infoOrErrorMessage.isError = true;
    infoOrErrorMessage.title = paymentFailedTitle;
    infoOrErrorMessage.message = paymentFailedReason;
  } else if (
    sellerPurchasedSubscriptionPlan?.is_trial &&
    !sellerPurchasedSubscriptionPlan?.is_trial_ended
  ) {
    infoOrErrorMessage.isError = false;
    infoOrErrorMessage.title = t('congratulations_your_free_trial_is_now_active');
    infoOrErrorMessage.message = '';
  } else if (
    sellerPurchasedSubscriptionPlan?.is_trial_ended &&
    !sellerPurchasedSubscriptionPlan?.is_subscription_plan
  ) {
    infoOrErrorMessage.title = t('your_5_day_free_trial_has_ended');
    infoOrErrorMessage.message = '';
  } else if (sellerPurchasedSubscriptionPlan?.plan_name === 'Promotional Plan') {
    infoOrErrorMessage.isError = false;
    infoOrErrorMessage.title = `${t('your')} ${currencyDetails?.currency}${
      currencyDetails?.promotional
    }${t('your_10_dollar_per_mont_promotional_plan_for_three_months_has_begun')}`;
    // t(
    //   'your_10_dollar_per_mont_promotional_plan_for_three_months_has_begun'
    // );
    // (currencyDetails?.currency currencyDetails?.promotional)
    infoOrErrorMessage.message = '';
  } else if (
    sellerPurchasedSubscriptionPlan?.is_subscription_plan &&
    completedStep !== 3
  ) {
    infoOrErrorMessage.isError = false;
    infoOrErrorMessage.title = t('c_ur_sub_active');
    infoOrErrorMessage.message = t('try_exploring_store_management');
  } else if (isSeller && isSellerMissedDataToFillDuringStripeVerification) {
    infoOrErrorMessage.isError = false;
    infoOrErrorMessage.title = '';
    infoOrErrorMessage.message = t('congratulations_you_provided_enough_info');
  } else if (isStripeVerified && !isSellerHaveTradewindsPlatformAccess) {
    infoOrErrorMessage.isError = false;
    infoOrErrorMessage.title = t('u_have_successfully_verified_your_account');
    infoOrErrorMessage.message = incompleteStripeVerificationErrorMessage;
  } else if (
    !canIBypassStripeVerification &&
    isSellerHaveTradewindsPlatformAccess &&
    completedStep === 2
  ) {
    infoOrErrorMessage.isError = false;
    infoOrErrorMessage.title = t('onboarding_and_verification_complete');
    infoOrErrorMessage.message = incompleteStripeVerificationErrorMessage;
  } else if (isSellerHaveTradewindsPlatformAccess && completedStep === 3) {
    infoOrErrorMessage.isError = false;
    infoOrErrorMessage.title = t('welcome');
    infoOrErrorMessage.message = t('dashboard_unlocked') + '!';
  }

  return (
    <div className='tw-relative tw-pb-4'>
      <div className='tw-relative tw-w-full tw-h-[270px]'>
        <img
          src={bannerImageUrl || '/images/placeholder-banner.svg'}
          alt={'banner'}
          className='tw-w-full tw-h-full tw-rounded-md tw-object-cover tw-bg-center'
        />

        {/* layers for banner */}
        <div className='tw-bg-white tw-opacity-40 tw-absolute tw-inset-0'></div>

        {/* Messages Container */}
        <div className='tw-bg-white/90 tw-rounded-md tw-w-11/12 lg:tw-w-[711px] tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-10 md:tw-top-1/2 md:-tw-translate-y-1/2 tw-py-2 md:tw-py-6 tw-px-2 md:tw-px-6'>
          {/* Message */}
          <div className='tw-grid tw-grid-cols-12 tw-gap-x-5 tw-items-center'>
            <div className='tw-col-span-1'>
              {isSubscriptionLoading ? (
                <Skeleton className='!tw-w-5 !tw-h-5 !tw-rounded-full md:!tw-w-12 md:!tw-h-12' />
              ) : (
                <>
                  {!infoOrErrorMessage.isError ? (
                    <CheckCircleIcon className='tw-text-green-600 tw-w-5 md:tw-w-12' />
                  ) : (
                    <ExclamationCircleIcon className='tw-text-red-600 tw-w-5 md:tw-w-12' />
                  )}
                </>
              )}
            </div>

            <div className='tw-col-span-11 tw-text-sm md:tw-text-base lg:tw-text-[21px] lg:tw-leading-[25.8px] tw-text-[#575858]'>
              {isSubscriptionLoading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <>
                  <p className='tw-font-semibold tw-mb-0 tw-capitalize'>
                    {infoOrErrorMessage?.title}
                  </p>
                  <p className='tw-capitalize'>{infoOrErrorMessage?.message}</p>
                </>
              )}
            </div>
          </div>

          {/* Stepper */}
          <div className='tw-flex tw-items-center tw-justify-center tw-mt-2 sm:tw-mt-4'>
            <div className='tw-flex tw-items-center tw-space-x-2'>
              <span className='tw-hidden sm:tw-block tw-text-sm tw-font-semibold tw-text-[#575858]'>
                {t('start')}
              </span>
              <Stepper steps={steps} />
              <span className='tw-hidden sm:tw-block tw-text-sm tw-font-semibold tw-text-[#575858]'>
                {t('done')}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Logo */}
      <div className='tw-absolute tw-bottom-0 tw-left-8 tw-w-[74px] tw-h-[74px] tw-bg-white tw-shadow tw-rounded-md tw-overflow-hidden'>
        {logoUrl && (
          <img
            src={logoUrl}
            alt={'logo'}
            className='tw-w-full tw-h-full tw-object-cover tw-bg-center'
          />
        )}
      </div>
    </div>
  );
};

export default ProfileBanner;

const Stepper = (
  props = {
    steps: [
      {
        title: '',
        isActive: false
      }
    ]
  }
) => {
  const { steps } = props;

  return (
    <div className='tw-flex tw-items-center'>
      {steps?.map((step, index) => {
        const isLastStep = steps?.length - 1 === index;

        const stepBackgroundColor = step?.isActive
          ? 'tw-bg-green-600'
          : 'tw-bg-[#DCDBDB]';

        return (
          <div className='tw-flex tw-items-center'>
            <Step {...step} />
            {!isLastStep && (
              <div
                className={`tw-w-20 sm:tw-w-40 md::tw-w-56 tw-h-1 ${stepBackgroundColor}`}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Step = props => {
  const { title, isActive } = props;

  const stepBackgroundColor = isActive ? 'tw-bg-green-600' : 'tw-bg-[#DCDBDB]';

  return (
    <div className='tw-flex tw-items-center'>
      <div
        className={`tw-w-6 tw-h-6 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white ${stepBackgroundColor}`}
      >
        {title}
      </div>
    </div>
  );
};
