import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

axiosInstance.interceptors.request.use(request => {
  const token =
    localStorage.getItem('accessToken') || localStorage.getItem('access_token');
  const orderFeedbackToken = localStorage.getItem('order_feedback_access_token');
  if (token || orderFeedbackToken) {
    request.headers.Authorization = `Bearer ${orderFeedbackToken || token}`;
  }

  return request;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error?.response?.request?.status === 401 && !originalRequest?._retry) {
      try {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refresh_token');
        await refreshTokenHandler(refreshToken);
        return axiosInstance.request(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const refreshTokenHandler = async refreshToken => {
  try {
    const { data } = await axiosInstance.post(`auth/refresh/${refreshToken}`);
    const accessToken = data?.data?.token;
    localStorage.setItem('access_token', data?.data?.token);
    axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}; // End of refreshTokenHandler

const axiosService = async (
  url,
  options,
  multipart = false,
  timeout = 30000,
  useV2 = false
) => {
  try {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    let optionsModified = {};
    const token =
      localStorage.getItem('accessToken') || localStorage.getItem('access_token');

    if (
      (options && options.method === 'post') ||
      (options && options.method === 'put') ||
      (options && options.method === 'patch')
    ) {
      if (multipart) {
        optionsModified = {
          ...options,
          data: options?.body || {},
          url: `${apiBaseUrl}${url}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        };
      } else {
        optionsModified = {
          ...options,
          ...optionsModified,
          data: options?.body || {},
          url: `${apiBaseUrl}${url}`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
      }
    } else if (options && options.method === 'delete') {
      if (multipart) {
        optionsModified = {
          ...options,
          data: options?.body || {},
          url: `${apiBaseUrl}${url}`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
      } else {
        optionsModified = {
          ...options,
          ...optionsModified,
          data: JSON.stringify(options?.body || {}),
          url: `${apiBaseUrl}${url}`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
      }
    } else {
      optionsModified = {
        ...options,
        ...optionsModified,
        url: `${apiBaseUrl}${url}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return axios(optionsModified)
      .then(result => {
        return handleResponse(result);
      })
      .catch(err => {
        console.log('Error in fetch ', err, err.response, err?.response?.data?.error);
        if (err?.response?.data?.error === 'email id already exist')
          return {
            success: false,
            message: 'Email already exists',
            isDuplicateEmail: true
          };
        else return { success: false, message: err?.response?.data?.message };
      });
  } catch (e) {
    new Promise((_, reject) => reject(new Error('Unable to Process Request')));
  }
};

const handleResponse = (response, isLogin) => {
  const contentType = response.headers['content-type'];
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return Promise.resolve(response.data)
      .then(data => {
        if (response.status === 401 && !isLogin) window.location.replace('/');
        return data;
      })
      .catch(error => {
        console.error('handleResponse.json.Error:', error);
      });
  } else {
    return Promise.resolve(response.statusText)
      .then(text => {
        return text;
      })
      .catch(error => {
        console.error('handleResponse.text.Error:', error);
      });
  }
};

export default axiosService;
