import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';

import './i18n';

import './index.css';
import LoaderPopup from './pages/new-seller-profile/components/loader-poup';

ReactDOM.render(
  <>
    <React.StrictMode>
      <Suspense fallback={<LoaderPopup isOpen={true} title=' ' message=' ' />}>
        <App />
      </Suspense>
    </React.StrictMode>
  </>,
  document.getElementById('root')
);
