import { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';

const VideoPreviewModal = props => {
  const { iframeUrl } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(null);

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className='tw-outline-none tw-border-none tw-absolute tw-top-12 tw-right-1 tw-z-50 tw-animate-pulse hover:tw-animate-none'
      >
        <img src='/icons/video-icon.png' alt='video-icon' />
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='tw-relative tw-z-10'
          onClose={() => setIsOpen(prevState => !prevState)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-25' />
          </Transition.Child>

          <div className='tw-fixed tw-inset-0 tw-overflow-y-auto'>
            <div className='tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='tw-w-[280px] sm:tw-w-1/2 w-h-[300px] lg:w-h-fit stw-h-fit tw-overflow-hidden tw-rounded-2xl tw-bg-white tw-shadow-xl tw-transition-all'>
                  <button
                    draggable='true'
                    onDrag={e => {
                      console.log('onDrag = ', e);
                    }}
                  >
                    Move
                  </button>
                  <iframe
                    src={iframeUrl}
                    title='YouTube video player'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen
                    allowTransparency
                    className='tw-w-full tw-h-[300px] lg:tw-h-[508px]'
                  ></iframe>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}; // End of VideoPreviewModal

export default VideoPreviewModal;
