import { lazy, useEffect, useState } from 'react';

// Third party packages
import { useTranslation } from 'react-i18next';

// components
const ProfileAccordion = lazy(() => import('./profile-accordion'));
const SellerCertificates = lazy(() =>
  import('./../../../../views/profile/SellerCertificates')
);
const SellerStorefront = lazy(() => import('../../../../views/profile/SellerStorefront'));
const SellerAddress = lazy(() => import('../../../../views/profile/SellerAddress'));
const SellerPreferences = lazy(() =>
  import('../../../../views/profile/SellerPreferences')
);
const ShippingManager = lazy(() =>
  import('../../../../views/profile/ShippingManager/ShippingManager')
);
const TermsPreferences = lazy(() => import('../../../../views/profile/TermsPreferences'));
const Notification = lazy(() => import('../../../../views/profile/Notification'));

const StoreManagementAccordion = props => {
  const {
    isSeller,
    isSellerHaveTradewindsPlatformAccess,
    sellerData,
    setIsStoreManagementCompleted
  } = props;
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation(['seller_onboarding']);

  const [isRFQPreferencesCompleted, setIsRFQPreferencesCompleted] = useState(false);
  const [isShippingManagerCompleted, setIsShippingManagerCompleted] = useState(false);

  const storeFront = {
    ...sellerData?.store_front,
    store_name: sellerData?.store_name || ''
  };
  const isMangeStorefrontCompleted =
    storeFront?.about_information && storeFront?.store_banner && storeFront?.store_logo;

  const sellerCertificates = sellerData?.certificates || [];
  const isCertificateAvailable = sellerCertificates?.length > 0;

  const sellerCountryId = sellerData?.edges?.country?.id;

  const sellerTermsAndCondition = sellerData?.terms_and_condition;
  const isTermsAndConditionAvailable = sellerData?.terms_and_condition?.en !== '';

  const isStoreManagementCompleted = isSeller
    ? isMangeStorefrontCompleted &&
      isCertificateAvailable &&
      sellerCountryId &&
      isRFQPreferencesCompleted &&
      isShippingManagerCompleted &&
      isTermsAndConditionAvailable
    : isRFQPreferencesCompleted;

  useEffect(() => {
    setIsStoreManagementCompleted(isStoreManagementCompleted);
  }, [isStoreManagementCompleted]);

  const sellerRFQPreferences = (
    <SellerPreferences
      accountVerification={isSellerHaveTradewindsPlatformAccess}
      profileData={sellerData}
      setIsRFQPreferencesCompleted={setIsRFQPreferencesCompleted}
    />
  );

  return (
    <ProfileAccordion
      title={`2. ${t('store_management')}`}
      disabled={!isSellerHaveTradewindsPlatformAccess}
      isVerified={isStoreManagementCompleted}
      defaultOpen={isSellerHaveTradewindsPlatformAccess}
      panelClassName='!tw-pl-4 sm:tw-pl-10'
    >
      {isSeller && (
        <div className='tw-pb-8 tw-space-y-4'>
          {/* Store Front */}
          <SellerStorefront
            accountVerification={isSellerHaveTradewindsPlatformAccess}
            storeFrontInfo={storeFront}
          />

          {/* Certificates */}
          <SellerCertificates
            accountVerification={isSellerHaveTradewindsPlatformAccess}
            key={sellerCertificates?.length}
            certification={sellerCertificates}
          />

          {/* Address Information */}
          <SellerAddress
            key={sellerCountryId}
            accountVerification={isSellerHaveTradewindsPlatformAccess}
            countryId={sellerCountryId}
          />

          {/* RFQ Preferences */}
          {sellerRFQPreferences}

          {/* Shipping Manager */}
          <ShippingManager
            setIsShippingManagerCompleted={setIsShippingManagerCompleted}
          />

          {/* Terms and Conditions Preferences */}
          <TermsPreferences
            accountVerification={isSellerHaveTradewindsPlatformAccess}
            getProfileData={() => {}}
            termsAndConditions={sellerTermsAndCondition}
          />

          {/* Notification Center */}
          <Notification
            selectedLang={language}
            accountVerification={isSellerHaveTradewindsPlatformAccess}
            notificationsData={sellerData?.notification_center}
          />
        </div>
      )}

      {!isSeller && (
        <div className='tw-pb-8'>
          {/* RFQ Preferences */}
          {sellerRFQPreferences}
        </div>
      )}
    </ProfileAccordion>
  );
}; // End of ProfileAccordion

export default StoreManagementAccordion;
