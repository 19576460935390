import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { lazyWithRetry } from './component-retry';

import Login from '../views/login/Login';
// const Login = lazyWithRetry(() => import('../views/login/Login'));
const ForgotPassword = lazy(() => import('../views/login/ForgotPassword'));
const Verify = lazy(() => import('../views/login/Verify'));
const ThanksForRegistering = lazy(() =>
  import('../views/thanksforregistering/ThanksForRegistering')
);

const SellerRegistration = lazyWithRetry(() =>
  import('../views/sellerregistration/SellerRegistration')
);
const SellerRegistrationEco = lazyWithRetry(() =>
  import('../views/sellerregistration/SellerRegistrationEco')
);

const StripeSuccess = lazy(() => import('../views/profile/StripeSuccess'));
const OrderFeedback = lazy(() => import('../views/login/OrderFeedback'));
const BusinessAgentTermsAndConditionPage = lazy(() =>
  import('../views/business-agents-terms-and-conditions')
);
const PrivacyPolicyPage = lazy(() => import('../views/privacy-policy'));
const TermsAndConditionPage = lazy(() => import('../views/terms-and-condition'));

export const publicRoutes = isAuth => {
  const routes = [
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/verify',
      element: <Verify />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/order-feedback',
      element: <OrderFeedback />
    },
    {
      path: '/payment-success',
      element: <StripeSuccess />
    },
    {
      path: '/seller-registration',
      element: <SellerRegistration />
    },
    {
      path: '/eco/seller-registration',
      element: <SellerRegistrationEco />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />
    },
    {
      path: '/terms-and-condition',
      element: <TermsAndConditionPage />
    },
    {
      path: '/business-agents-terms-and-conditions',
      element: <BusinessAgentTermsAndConditionPage />
    },
    {
      path: '/bdm-registration',
      element: <SellerRegistration isBda={true} />
    },
    {
      path: '/thanks-for-registering',
      element: <ThanksForRegistering />
    },
    { path: '*', element: <Navigate to='/login' replace /> }
  ];

  if (!isAuth) {
    routes.push({
      path: '/login',
      element: <Login />
    });
  }

  return routes;
};
