import { useEffect, useState } from 'react';

// Third party packages
import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
  ShareIcon
} from '@heroicons/react/24/solid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// component
import ElevatedButton from './buttons.component';

const SellerInfo = props => {
  const {
    isSeller,
    name,
    countryName,
    email,
    businessName,
    joinedDate,
    tradewindsEmail,
    storeName,
    storeUrl,
    websiteUrl,
    phoneNumber,
    isSellerHaveTradewindsPlatformAccess,
    updateSellerOrBDMInfoHandler
  } = props;

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isStoreUrlCopied, setIsStoreUrlCopied] = useState(false);

  const { t } = useTranslation(['seller_onboarding']);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email,
      business_name: businessName,
      tradewindsEmail,
      website: websiteUrl,
      phone_number: phoneNumber
    }
  });

  useEffect(() => {
    let setTimeoutHandler;
    if (isStoreUrlCopied) {
      setTimeoutHandler = setTimeout(() => {
        setIsStoreUrlCopied(false);
      }, 500);
    }

    return () => clearTimeout(setTimeoutHandler);
  }, [isStoreUrlCopied]);

  const onSubmit = async data => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    updateSellerOrBDMInfoHandler?.(data);
    setIsReadOnly(true);
    setIsLoading(false);
  }; // End of onSubmit

  const isError = Object.keys(errors)?.length > 0;

  return (
    <div>
      {/* Name and Country */}
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-[#DCDBDB]'>
        <div className='tw-flex tw-items-center md:tw-space-x-3'>
          <span className='tw-text-[10px] lg:tw-text-[21px] tw-font-semibold tw-capitalize'>
            {name}
          </span>
          <button
            onClick={
              isSellerHaveTradewindsPlatformAccess
                ? () => setIsReadOnly(prevState => !prevState)
                : null
            }
            className={`'tw-outline-none ${
              !isSellerHaveTradewindsPlatformAccess ? '!tw-cursor-not-allowed' : ''
            }`}
          >
            <PencilSquareIcon
              className={`${
                isReadOnly ? 'tw-text-[#575858]' : 'tw-text-blue-700'
              } tw-w-5`}
            />
          </button>
        </div>
        <span className='tw-text-[10px] lg:tw-text-[18px] tw-font-semibold tw-capitalize'>
          {countryName}
        </span>
      </div>

      {/* Other Info */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='tw-mt-6 tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-4'>
          <div className='tw-col-span-12 sm:tw-col-span-5'>
            <SellerInfoInput readOnly={true} label='Email' value={email} />
          </div>
          <div className='tw-col-span-12 sm:tw-col-span-4'>
            <SellerInfoInput
              readOnly={isReadOnly}
              label={t('business_name')}
              value={watch('business_name')}
              register={{
                ...register('business_name', { required: t('business_name_is_required') })
              }}
              errorMessage={errors?.business_name?.message}
            />
          </div>
          <div className='tw-col-span-12 sm:tw-col-span-3'>
            <SellerInfoInput readOnly={true} label='Date Joined' value={joinedDate} />
          </div>
          <div className='tw-col-span-12 sm:tw-col-span-5'>
            <SellerInfoInput
              readOnly={true}
              label={t('tradewinds_email')}
              value={tradewindsEmail}
            />
          </div>
          <div className='tw-col-span-12 sm:tw-col-span-4'>
            {isSeller ? (
              <div className='tw-flex tw-flex-col tw-items-start tw-space-y-2'>
                <a href={storeUrl}>Visit Store</a>
                <button
                  type='button'
                  onClick={async () => {
                    try {
                      if (!window?.navigator?.share) {
                        await navigator?.clipboard?.writeText(storeUrl);
                        setIsStoreUrlCopied(true);
                        return;
                      }

                      window?.navigator?.share({
                        text: `${t('share')} ${storeName}`,
                        url: storeUrl
                      });
                    } catch (error) {}
                  }}
                  className='tw-flex tw-items-center tw-space-x-2 tw-bg-blue-500 tw-rounded-md tw-text-white tw-px-2 tw-py-1 tw-text-xs'
                >
                  <span>
                    {window?.navigator?.share
                      ? t('share_your_store')
                      : isStoreUrlCopied
                      ? t('copied')
                      : t('copy_store_url')}
                  </span>
                  {window?.navigator?.share ? (
                    <ShareIcon className='tw-w-5' />
                  ) : (
                    <DocumentDuplicateIcon className='tw-w-5' />
                  )}
                </button>
              </div>
            ) : (
              <SellerInfoInput
                type='url'
                readOnly={isReadOnly}
                label={t('website')}
                value={watch('website')}
                register={{
                  ...register('website', {
                    required: t('website_is_required')
                  })
                }}
                errorMessage={errors?.website?.message}
              />
            )}
          </div>
          <div className='tw-col-span-12 sm:tw-col-span-3'>
            <SellerInfoInput
              readOnly={isReadOnly}
              label={t('phone_number')}
              value={watch('phone_number')}
              register={{
                ...register('phone_number', { required: t('phone_number_is_required') })
              }}
              errorMessage={errors?.phone_number?.message}
            />
          </div>
        </div>

        {!isReadOnly && (
          <div className='tw-flex tw-justify-center tw-mt-4 tw-space-x-4'>
            <ElevatedButton
              onClick={() => setIsReadOnly(true)}
              className='!tw-border !tw-bg-transparent !tw-text-red-800'
            >
              {t('cancel')}
            </ElevatedButton>

            <ElevatedButton
              type={isError ? 'button' : 'submit'}
              disabled={isError}
              className={isError ? 'tw-opacity-50 tw-cursor-not-allowed' : ''}
            >
              {isLoading ? (
                <span>{t('updating')} ...</span>
              ) : (
                <span>
                  {isError ? t('please_fill_all_the_required_info') : t('update_info')}
                </span>
              )}
            </ElevatedButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default SellerInfo;

export const SellerInfoInput = props => {
  const { register = {}, type, label, value, readOnly, errorMessage } = props;

  let inputClassName =
    'md:tw-text-lg md:tw-leading-[21.94px] tw-font-semibold tw-text-[#575858] tw-w-fit sm:tw-w-[164px] md:tw-w-[180px] tw-outline-none';

  if (!readOnly) {
    inputClassName = `${inputClassName} tw-px-2 tw-py-1 tw-border-[0.8px] tw-rounded-md tw-shadow-md ${
      errorMessage ? 'tw-border-red-700' : 'tw-border-blue-400'
    }`;
  }

  return (
    <div className='tw-flex tw-flex-col'>
      {readOnly ? (
        <p className={`${inputClassName}`}>{value}</p>
      ) : (
        <input
          type={type || 'text'}
          value={value}
          readOnly={readOnly}
          {...register}
          className={inputClassName}
        />
      )}
      <label
        className={`md:tw-text-lg md:tw-leading-[21.94px] tw-opacity-50 ${
          errorMessage ? 'tw-text-red-700' : 'tw-text-[#575858]'
        }`}
      >
        {errorMessage || label}
      </label>
    </div>
  );
};
