import 'react-circular-progressbar/dist/styles.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import 'react-notifications-component/dist/theme.css';
import './css/bootstrap-custom.scss';
import './assets/fonts/Roboto/font.css';
import './assets/fonts/Montserrat/font.css';
import './css/app.scss';
import 'react-toastify/dist/ReactToastify.css';

// Third party packages
import { SWRConfig } from 'swr';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Third party style
import 'react-loading-skeleton/dist/skeleton.css';

// utils
import { axiosInstance } from './service/axiosservice';

import React from 'react';
import './config/axios.config';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { privateRoutes } from '../routes/private-routes.jsx';
import PrivateRouteApp from '../routes/PrivateRouteApp';

export default function App() {
  const router = createBrowserRouter(privateRoutes);

  return (
    <PrivateRouteApp>
      <SWRConfig
        value={{
          fetcher: axiosInstance.get
        }}
      >
        <RouterProvider router={router} />
      </SWRConfig>
    </PrivateRouteApp>
  );
}
