import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import fetch from '../service/axiosservice';
let refreshToken = localStorage.getItem('refreshToken');
let isLogged = true;

const refreshTokenCall = () => {
  isLogged = false;

  fetch(`api/v1/auth/refresh/${refreshToken}`, {
    method: 'post'
  })
    .then(response => {
      if (response.success == true) {
        isLogged = false;
        let data = response.data;
        localStorage.setItem('accessToken', data?.access_token?.token);

        localStorage.setItem('refreshToken', data?.refresh_token?.token);
      } else {
        isLogged = false;
        confirmAlert({
          message: ' Session expired, Please login again',
          buttons: [
            {
              label: 'Login',
              onClick: () => {
                if (window.location.origin === 'http://localhost:3000') {
                  window.location.href = 'http://localhost:3000/#/';
                } else if (
                  window.location.origin ===
                  'https://tradewinds-dev-public.s3.us-east-2.amazonaws.com'
                ) {
                  window.location.href =
                    'https://tradewinds-dev-public.s3.us-east-2.amazonaws.com/index.html#/';
                } else if (window.location.origin === 'https://www.tradewindsqa.com') {
                  window.location.href = 'https://www.tradewindsqa.com/#/';
                }
              }
            }
          ],
          closeOnClickOutside: false
        });
      }
    })
    .catch(error => {
      isLogged = false;
      if (error?.response?.data?.status === 401) {
        confirmAlert({
          // title: 'Login',
          message: ' Session expired, Please login again',
          buttons: [
            {
              label: 'Login',
              onClick: () => {
                if (window.location.origin === 'http://localhost:3000') {
                  window.location.href = 'http://localhost:3000/#/';
                } else if (
                  window.location.origin ===
                  'https://tradewinds-dev-public.s3.us-east-2.amazonaws.com'
                ) {
                  window.location.href =
                    'https://tradewinds-dev-public.s3.us-east-2.amazonaws.com/index.html#/';
                } else if (window.location.origin === 'https://www.tradewindsqa.com') {
                  window.location.href = 'https://www.tradewindsqa.com/#/';
                }
              }
            }
          ],
          closeOnClickOutside: false
        });
      }
    });
};

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = localStorage.getItem('accessToken');
    let refreshToken = localStorage.getItem('refreshToken');
    axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    console.log('responseeeee', response);
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.data?.status === 401 && isLogged) {
      if (error?.response?.data?.message != 'Invalid email or password') {
        refreshTokenCall();
      }
    }
    return Promise.reject(error);
  }
);
