import React, { lazy, useEffect, useState } from 'react';

// Third party packages
import {
  Navigate,
  useLocation,
  useNavigate,
  useRouteError,
  useSearchParams
} from 'react-router-dom';

import './route.scss';
import { publicRoutes } from './public-routes';
import { axiosInstance } from '../app/service/axiosservice';
import { toast } from 'react-toastify';
import { lazyWithRetry } from './component-retry';

import NewSellerProfile from '../pages/new-seller-profile/new-seller-profile';

// const NewSellerProfile = lazyWithRetry(() =>
//   import('../pages/new-seller-profile/new-seller-profile.jsx')
// );

const Layout = lazy(() => import('../components/layout'));

const LoaderPopup = lazy(() =>
  import('../pages/new-seller-profile/components/loader-poup')
);

const AccountManagement = lazy(() =>
  import('../views/accountmanagement/AccountManagementPage')
);
const AdminDashboardPage = lazy(() =>
  import('../views/admin-dashboard/AdminDashboardPage')
);

const DashboardPage = lazy(() => import('../views/dashboard/DashboardPage'));
const MessageCenterComponent = lazy(() =>
  import('../views/messagecenternew/MessageCenterComponent')
);
const ClientTransactionsPage = lazy(() =>
  import('../views/clienttransactions/ClientTransactionsPage')
);
const NewClientTransactionsPage = lazy(() =>
  import('../views/clienttransactions/NewClientTransactionsPage')
);
const PerformancePage = lazy(() => import('../views/performance/PerformancePage'));
const ActivationPage = lazy(() => import('../views/activations/ActivationPage'));
const ActivationApprovals = lazy(() =>
  import('../views/activations/components/ActivationApprovals')
);
const UserPage = lazy(() => import('../views/admin-users/UserPage'));
const MarketingPage = lazy(() => import('../views/marketing/MarketingPage'));
const ProductLanding = lazy(() => import('../views/products/ProductsLanding'));
const CustomerService = lazy(() => import('../views/customerService/CustomerService'));
const ProfilePage = lazyWithRetry(() => import('../views/profile/ProfilePage'));

// const ProfilePage = lazy(() => import('../views/profile/ProfilePage'));
const ProfilePage2 = lazy(() => import('../views/profile2/ProfilePage'));
const EmailSetupPage = lazy(() => import('../views/email/EmailSetupPage'));
const EmailActivityPage = lazy(() => import('../views/email/email-activity'));
const SellersPage = lazy(() => import('../views/sellers/SellersPage'));
const ActivationsAndApprovals = lazy(() =>
  import('../views/activations-approvals/ActivationsApprovals')
);
const ActivationsApprovalsDetails = lazy(() =>
  import(
    '../views/activations-approvals/activation-approvals-details/ActivationsApprovalsDetails'
  )
);
const GlobalTransactionsPage = lazy(() =>
  import('../views/global-transactions/GlobalTransactionsPage')
);
const PaymentsPage = lazy(() => import('../views/payments/Payments'));
const BuyerDashboardPage = lazy(() =>
  import('../views/buyer-dashboard/BuyerDashboardPage')
);
const BuyerAccountPage = lazy(() =>
  import('../views/buyer-dashboard/buyerAccountPage/BuyerAccountPage')
);
const SupplierActivityPage = lazy(() =>
  import('../views/buyer-dashboard/supplierActivity/SupplierActivityPage')
);
const BuyerOrdersAndInvoice = lazy(() =>
  import('../views/buyer-dashboard/ordersAndInvoices/ClientTransactionsPage')
);
const BuyerCustomerService = lazy(() =>
  import('../views/buyer-dashboard/customerService/CustomerService')
);
const BuyerRFQ = lazy(() => import('../views/buyer-dashboard/buyerRFQPage/BuyerRFQPage'));
const SellerPayouts = lazy(() => import('../views/sellerpayout/SellerPayouts'));
const BDMPayouts = lazy(() => import('../views/bdmpayout/BDMPayouts'));
// import BDMPayouts from '../views/bdmpayout/BDMPayouts';

const StripeSuccess = lazy(() => import('../views/profile/StripeSuccess'));
const RFQManagement = lazy(() => import('../views/rfqmanagement/RFQManagement'));
const PlatformStatusPage = lazy(() =>
  import('../views/platformstatus/PlatformStatusPage')
);
const CMSHomePage = lazy(() => import('../views/cms-home/CMSHomePage'));
const ShippingMethodPage = lazy(() => import('../views/shippingmethod/ShippingMethod'));
const AdminSellerPayments = lazy(() =>
  import('../views/admin-seller-payments/AdminSellerPayments')
);
const CMSCategoriesPage = lazy(() => import('../views/cms-categories/CMSCategoriesPage'));
const CmsCategoryEditContainer = lazy(() =>
  import('../views/cms-category-edit/DraggableCmsCategoryEdit')
);

const CMSSeoContainer = lazy(() => import('../views/cms-seo/CMSSeoContainer'));
// import CMSSeoContainer from '../views/cms-seo/CMSSeoContainer';

const AdminClientTransactions = lazy(() =>
  import('../views/admin-client-transactions/AdminClientTransactions')
);
const MySellers = lazy(() => import('../views/bdm/bdm-sellers/SellerList'));
const BdmDashboard = lazy(() => import('../views/bdm/bdm-dashboard/BdmDashboard'));
const AdminMessageCenter = lazy(() =>
  import('../views/admin-message-center/AdminMessageCenter')
);
const AdminStripe = lazy(() => import('../views/admin-stripe/AdminStripe'));

const errorKey = 'page_refreshed_because_of_error_count';

export const PrivateRoute = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const accessKey = searchParams.get('access_key');
  const refreshKey = searchParams.get('refresh_key');
  // localStorage.removeItem(errorKey);

  const fetchAndSetCurrentUserData = async () => {
    const { data: currentUserResponse } = await axiosInstance.get(
      'auth/get-current-user'
    );
    const currentUserData = currentUserResponse?.data;
    const { id: userId, edges = {} } = currentUserData || {};
    const currentUserRole = (edges?.roles?.[0]?.name || '')?.toLowerCase();

    localStorage.setItem('tw_user_id', userId);
    localStorage.setItem('tw_user_data', JSON.stringify(currentUserData || {}));

    if (currentUserRole === 'bdm') {
      const bdmData = edges?.bdm;

      localStorage.setItem('isStripeVerified', bdmData?.is_verified_stripe);
      localStorage.setItem('bdm-id', bdmData?.id);
      localStorage.setItem('tw-bdmInfo', JSON.stringify(bdmData));
      localStorage.setItem('user-Type', 'BDM');
      localStorage.setItem('isBdm', 3);
      localStorage.setItem('userIsAdmin', 3);
      navigate('/profile', { replace: true });
    } else if (currentUserRole === 'seller') {
      const sellerData = edges?.seller || {};
      localStorage.setItem('tw-sellerId', sellerData?.id);
      localStorage.setItem('tw_seller_data', JSON.stringify(sellerData));
      localStorage.setItem('tw-seller_id', sellerData?.id);
      localStorage.setItem('tw_seller_user_id', userId);
      localStorage.setItem('tw-sellerInfo', JSON.stringify(sellerData));
      localStorage.setItem('isStripeVerified', sellerData?.is_stripe_verified);
      localStorage.setItem('seller_preferId', sellerData?.edges?.rfq_preference?.id);
      localStorage.setItem('tw-isAdmin', false);
      localStorage.setItem('user-Type', 'Seller');
      localStorage.setItem('tw-twmemail', currentUserData?.tradewinds_email);
      localStorage.setItem('tw_twm_email', currentUserData?.tradewinds_email);
      localStorage.setItem('userIsAdmin', 1);

      navigate('/profile', { replace: true });
    } else if (currentUserRole === 'admin') {
      localStorage.setItem('userIsAdmin', 0);
      localStorage.setItem('tw_admin_user_id', currentUserData?.id);
      localStorage.setItem('tw-sellerInfo', JSON.stringify(currentUserData));
      localStorage.setItem('tw-isAdmin', true);
      localStorage.setItem('user-Type', 'Admin');
      localStorage.setItem('tw-twmemail', currentUserData?.tradewinds_email);

      navigate('/admin-dashboard', { replace: true });
    } else if (currentUserRole === 'buyer') {
      if (currentUserData?.id) {
        localStorage.setItem('tw-buyerId', currentUserData?.edges?.buyer?.id);
        localStorage.setItem(
          'tw-sellerInfo',
          JSON.stringify(currentUserData?.first_name)
        );
        const buyerId = currentUserData?.edges?.buyer?.id;
        localStorage.setItem('tw-buyer_id', buyerId);
        localStorage.setItem('tw-userid', currentUserData?.id);
        let isBuyer = 2;
        localStorage.setItem('userIsAdmin', isBuyer);
        localStorage.setItem('tw-buyerId', buyerId);
        localStorage.setItem('tw-useId', currentUserData?.id);
        localStorage.setItem('user-Type', 'Buyer');
        localStorage.setItem('tw-buyerEmail', currentUserData?.tradewinds_email);
        localStorage.setItem('tw_twm_email', currentUserData?.tradewinds_email);
        navigate('/buyers', { replace: true });
      }
    }
  }; // End of fetchAndSetCurrentUserData

  useEffect(() => {
    const language = localStorage.getItem('i18nextLng');
    if (!language) {
      localStorage.setItem('i18nextLng', 'en');
    }

    setIsAuthenticating(true);
    if (accessKey) {
      localStorage.clear();
      localStorage.setItem('access_token', accessKey);
      localStorage.setItem('refresh_token', refreshKey);

      fetchAndSetCurrentUserData()
        .then(() => {
          setIsAuth(true);
          navigate(location.pathname);
          console.log('llllll =', location.pathname);
        })
        .catch(error => {
          toast.error(error?.response?.data?.message || 'Something went wrong');
          localStorage.clear();
          navigate('/login');
        })
        .finally(() => setIsAuthenticating(false));
    } else {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        setIsAuth(true);
      }
      setIsAuthenticating(false);
    }
  }, [accessKey]);

  /**
   * Parsing this because in email template and some other place we are using #routing in url which has been removed.
   * To handle those url I am parsing # url and converting them in a url that can understood by latest routing package.
   */
  // const [_, fullUrl] = (location.hash || '')?.split('#/');

  // if (fullUrl) {
  //   const [path, queryString] = fullUrl?.split('/?');
  //   return <Navigate to={`/${path}?${queryString || ''}`} />;
  // }

  if (isAuthenticating) {
    return <LoaderPopup open={true} message='Authenticating ...' />;
  }

  return isAuth ? <Layout /> : <Navigate to='/login' />;
};

function ErrorBoundary() {
  // const error = useRouteError();
  const pageRefreshedBecauseOfErrorCount = localStorage.getItem(errorKey);

  useEffect(() => {
    if (!pageRefreshedBecauseOfErrorCount) {
      localStorage.setItem(errorKey, 1);
      window.location.reload();
    } else {
      localStorage.removeItem(errorKey);
    }
  }, [pageRefreshedBecauseOfErrorCount]);

  if (!pageRefreshedBecauseOfErrorCount) {
    return <LoaderPopup open={true} message='Loading ...' />;
  }

  // Uncaught ReferenceError: path is not defined
  return (
    <div className='tw-flex tw-justify-center tw-items-center tw-h-screen'>
      <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-shadow-md tw-p-5 tw-rounded-lg tw-ring-1 tw-space-y-4'>
        <h1>Something went wrong</h1>
        <button
          onClick={() => window.location.reload()}
          className='tw-bg-blue-600 tw-px-2 tw-py-1 tw-rounded-md tw-text-white tw-h-10'
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
}

const userIsAdmin = localStorage.getItem('userIsAdmin');
let defaultPath = '/';
if (userIsAdmin === '0') {
  defaultPath = '/admin-dashboard';
} else if (userIsAdmin === '1') {
  defaultPath = '/profile';
} else if (userIsAdmin === '2') {
  defaultPath = '/buyers';
} else if (userIsAdmin === '3') {
  defaultPath = '/profile';
}

export const privateRoutes = [
  ...publicRoutes(),

  // Private routes
  {
    path: '/',
    element: <PrivateRoute />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <Navigate to={defaultPath} replace={true} />
      },
      {
        path: '/accountmanagement',
        element: <AccountManagement />
      },
      {
        path: '/admin-dashboard',
        element: <AdminDashboardPage />
      },
      {
        path: '/admin-messages',
        element: <AdminMessageCenter />
      },
      {
        path: '/dashboard',
        element: <DashboardPage />
      },
      {
        path: '/message-center',
        element: <MessageCenterComponent />
      },
      {
        path: '/client-transactions',
        element: <NewClientTransactionsPage />
      },
      {
        path: '/seller/client-transactions',
        element: <ClientTransactionsPage />
      },
      {
        path: '/global',
        element: <GlobalTransactionsPage />
      },
      {
        path: '/performance',
        element: <PerformancePage />
      },
      {
        path: '/users',
        element: <UserPage />
      },
      {
        path: '/admin-stripe',
        element: <AdminStripe />
      },
      {
        path: '/marketing',
        element: <MarketingPage />
      },
      {
        path: '/profile',
        element: <NewSellerProfile />
      },
      {
        path: '/seller/profile',
        element: <ProfilePage />
      },
      {
        path: '/profile2',
        element: <ProfilePage2 />
      },
      {
        path: '/products',
        element: <ProductLanding />
      },
      {
        path: '/email',
        element: <EmailSetupPage />
      },
      {
        path: '/email-activity',
        element: <EmailActivityPage />
      },
      {
        path: '/payments',
        element: <PaymentsPage />
      },
      {
        path: '/activation',
        element: <ActivationPage />
      },
      {
        path: '/activation2',
        element: <ActivationApprovals />
      },
      {
        path: '/activation&approvals',
        element: <ActivationsAndApprovals />
      },
      {
        path: '/customer-service',
        element: <CustomerService />
      },
      {
        path: '/buyers',
        element: <BuyerDashboardPage />
      },
      {
        path: '/buyer-accounts',
        element: <BuyerAccountPage />
      },
      {
        path: '/buyer-customer-service',
        element: <BuyerCustomerService />
      },
      {
        path: '/supplier-activities',
        element: <SupplierActivityPage />
      },
      {
        path: '/buyer-message-center',
        element: <MessageCenterComponent />
      },
      {
        path: '/buyer-orders-invoice',
        element: <BuyerOrdersAndInvoice />
      },
      {
        path: '/buyer-rfq',
        element: <BuyerRFQ />
      },
      {
        path: '/seller-payouts',
        element: <SellerPayouts />
      },
      {
        path: '/bdm-payouts',
        element: <BDMPayouts />
      },
      {
        path: '/admin-products',
        element: <ProductLanding isAdmin={true} />
      },
      {
        path: '/payment-success',
        element: <StripeSuccess />
      },
      {
        path: '/rfq-management',
        element: <RFQManagement />
      },
      {
        path: '/platforms',
        element: <PlatformStatusPage />
      },
      {
        path: '/cms-home',
        element: <CMSHomePage />
      },
      {
        path: '/admin-cms-home',
        element: <CMSHomePage />
      },
      {
        path: '/cms-categories',
        element: <CMSCategoriesPage />
      },
      {
        path: '/cms-categories-edit',
        element: <CmsCategoryEditContainer />
      },
      {
        path: '/cms-seo',
        element: <CMSSeoContainer />
      },
      {
        path: '/shipping-method',
        element: <ShippingMethodPage />
      },
      {
        path: '/admin-seller-payments',
        element: <AdminSellerPayments />
      },
      {
        path: '/admin-client-transactions',
        element: <AdminClientTransactions />
      },
      {
        path: '/details',
        element: <ActivationsApprovalsDetails />
      },
      {
        path: '/sellers',
        element: <SellersPage />
      },
      {
        path: '/bdm-dashboard',
        element: <BdmDashboard />
      },
      {
        path: '/my-sellers',
        element: <MySellers />
      }
    ]
  }
];
