import React, { useState, lazy } from 'react';
import './stylesheets/login.scss';

// Third party packages
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  LockClosedIcon
} from '@heroicons/react/24/solid';

// images
import twmain from './images/TW logo text.webp';

import { axiosInstance } from '../../app/service/axiosservice';

import { trackUserEvent } from '../../utils/track-user-event';
import { ToastContainer, toast } from 'react-toastify';
import { resendEmail } from '../sellerregistration/services/sellerRegistration';

// components
const ForgotPasswordEmailSendForm = lazy(() =>
  import('./forgot-password-email-send-form')
);

const LanguageDropdown = lazy(() =>
  import('../../components/elements/language-dropdown')
);

const Login = () => {
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [loginAs, setLoginAs] = useState('seller');
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm();

  const loginAsHandler = (value = '') => {
    setLoginAs(() => (value === loginAs ? 'admin' : value));
  }; // End of loginAsHandler

  const fetchAndSetCurrentUserData = async () => {
    const { data: currentUserResponse } = await axiosInstance.get(
      'auth/get-current-user'
    );
    const currentUserData = currentUserResponse?.data;
    const { id: userId, edges = {} } = currentUserData || {};
    const currentUserRole = (edges?.roles?.[0]?.name || '')?.toLowerCase();

    localStorage.setItem('tw_user_id', userId);
    localStorage.setItem('tw_user_data', JSON.stringify(currentUserData || {}));

    if (currentUserRole === 'bdm') {
      const bdmData = edges?.bdm;

      localStorage.setItem('isStripeVerified', bdmData?.is_verified_stripe);
      localStorage.setItem('bdm-id', bdmData?.id);
      localStorage.setItem('tw-bdmInfo', JSON.stringify(bdmData));
      localStorage.setItem('user-Type', 'BDM');
      localStorage.setItem('isBdm', 3);
      localStorage.setItem('userIsAdmin', 3);
      navigate('/profile', { replace: true });
    } else if (currentUserRole === 'seller') {
      const sellerData = edges?.seller || {};
      localStorage.setItem('tw-sellerId', sellerData?.id);
      localStorage.setItem('tw_seller_data', JSON.stringify(sellerData));
      localStorage.setItem('tw-seller_id', sellerData?.id);
      localStorage.setItem('tw_seller_user_id', userId);
      localStorage.setItem('tw-sellerInfo', JSON.stringify(sellerData));
      localStorage.setItem('isStripeVerified', sellerData?.is_stripe_verified);
      localStorage.setItem('seller_preferId', sellerData?.edges?.rfq_preference?.id);
      localStorage.setItem('tw-isAdmin', false);
      localStorage.setItem('user-Type', 'Seller');
      localStorage.setItem('tw-twmemail', currentUserData?.tradewinds_email);
      localStorage.setItem('tw_twm_email', currentUserData?.tradewinds_email);
      localStorage.setItem('userIsAdmin', 1);

      navigate('/profile', { replace: true });
    } else if (currentUserRole === 'admin') {
      localStorage.setItem('userIsAdmin', 0);
      localStorage.setItem('tw_admin_user_id', currentUserData?.id);
      localStorage.setItem('tw-sellerInfo', JSON.stringify(currentUserData));
      localStorage.setItem('tw-isAdmin', true);
      localStorage.setItem('user-Type', 'Admin');
      localStorage.setItem('tw-twmemail', currentUserData?.tradewinds_email);

      navigate('/admin-dashboard', { replace: true });
    } else if (currentUserRole === 'buyer') {
      if (currentUserData?.id) {
        localStorage.setItem('tw-buyerId', currentUserData?.edges?.buyer?.id);
        localStorage.setItem(
          'tw-sellerInfo',
          JSON.stringify(currentUserData?.first_name)
        );
        const buyerId = currentUserData?.edges?.buyer?.id;
        localStorage.setItem('tw-buyer_id', buyerId);
        localStorage.setItem('tw-userid', currentUserData?.id);
        let isBuyer = 2;
        localStorage.setItem('userIsAdmin', isBuyer);
        localStorage.setItem('tw-buyerId', buyerId);
        localStorage.setItem('tw-useId', currentUserData?.id);
        localStorage.setItem('user-Type', 'Buyer');
        localStorage.setItem('tw-buyerEmail', currentUserData?.tradewinds_email);
        localStorage.setItem('tw_twm_email', currentUserData?.tradewinds_email);
        navigate('/buyers', { replace: true });
      }
    }
  }; // End of fetchAndSetCurrentUserData

  const onSubmit = async userData => {
    localStorage.clear();
    setErrorMessage('');
    setIsEmailVerified(true);
    setIsLoading(true);

    try {
      const { data: loginData } = await axiosInstance.post('auth/login', {
        ...userData,
        login_as: loginAs
      });
      const { access_token, refresh_token } = loginData?.data || {};
      localStorage.setItem('access_token', access_token?.token);
      localStorage.setItem('refresh_token', refresh_token?.token);

      // Current User API Call
      await fetchAndSetCurrentUserData();
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      setErrorMessage(errorMessage || 'Something went wrong!');
      trackUserEvent('login', errorMessage || error);

      if (error?.response?.data?.status === 403) {
        setIsEmailVerified(error?.response?.data?.is_email_verified);
      }
    } finally {
      setIsLoading(false);
    }
  }; // End of onSubmit

  const resendEmailVerificationMail = async () => {
    const response = await resendEmail(getValues('email'), loginAs);

    if (response.success) {
      toast.success('Email Sent Successfully', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setIsEmailVerified(true);
      return;
    }

    toast.error('Something went wrong', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }; // End of resendEmailVerificationMail

  const redirect = redirectToPage => {
    navigate(redirectToPage);
  };

  const spinner = (
    <div role='status'>
      <svg
        aria-hidden='true'
        className='tw-w-8 tw-h-8 tw-mr-2 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600'
        viewBox='0 0 100 101'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
          fill='currentColor'
        />
        <path
          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
          fill='currentFill'
        />
      </svg>
      <span className='tw-sr-only'>Loading...</span>
    </div>
  );

  return (
    <>
      <ToastContainer />
      <ForgotPasswordEmailSendForm
        isOpen={isForgotPasswordModalOpen}
        onClose={() => setIsForgotPasswordModalOpen(false)}
        loginAs={loginAs}
      />{' '}
      <div className="tw-signup tw-bg-[url('/images/bg.webp')]">
        <div className='tw-flex tw-justify-end tw-p-2'>
          <LanguageDropdown className='' />
        </div>

        <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-5 sm:tw-mt-10'>
          <div className='tw-mb-5 tw-mx-5'>
            <img
              className=''
              src={'/images/tw-hstacked.webp'}
              alt=''
              width={280}
              height={280}
            />
          </div>

          <div className='tw-bg-white tw-rounded-md tw-w-fit md:tw-px-20 tw-py-8 tw-flex'>
            <div className='tw-w-72 tw-px-4'>
              <div className='text-center fw-bold pt-4'>
                {t('welcome_back')}
                <hr className='w-75 m-auto mt-2 tw-pb-4' />
              </div>

              <div className='tw-flex tw-justify-center tw-space-x-[15px] lg:tw-space-x-2 xl:tw-space-x-3'>
                <label className='tw-capitalize tw-flex tw-items-center tw-space-x-2'>
                  <input
                    className='tw-inline-block tw-h-[18px] tw-w-[18px] tw-border tw-align-text-top sm:tw-h-[23px] sm:tw-w-[23px] '
                    type='radio'
                    checked={loginAs === 'buyer'}
                    onClick={() => loginAsHandler('buyer')}
                  />
                  <span>{t('buyer')}</span>
                </label>
                <label className='tw-capitalize tw-flex tw-items-center tw-space-x-2'>
                  <input
                    className='tw-inline-block tw-h-[18px] tw-w-[18px] tw-border tw-align-text-top sm:tw-h-[23px] sm:tw-w-[23px] '
                    type='radio'
                    checked={loginAs === 'seller'}
                    onClick={() => loginAsHandler('seller')}
                  />
                  <span>{t('seller')}</span>
                </label>
                <label className='tw-capitalize tw-flex tw-items-center tw-space-x-2'>
                  <input
                    className='tw-inline-block tw-h-[18px] tw-w-[18px] tw-border tw-align-text-top sm:tw-h-[23px] sm:tw-w-[23px] '
                    type='radio'
                    checked={loginAs === 'bdm'}
                    onClick={() => loginAsHandler('bdm')}
                  />
                  <span>{t('BA')}</span>
                </label>
              </div>

              {isEmailVerified && errorMessage && (
                <p className='tw-capitalize tw-text-center tw-text-red-600 tw-font-medium tw-text-sm '>
                  {errorMessage}
                </p>
              )}

              {/* Email Verification */}
              {!isEmailVerified && (
                <div className='tw-text-xs tw-text-red-600'>
                  <span>Please verify your email, </span>
                  <button
                    type='button'
                    onClick={resendEmailVerificationMail}
                    className='tw-font-semibold tw-text-blue-600'
                  >
                    Click Here
                  </button>
                </div>
              )}

              <form className='tw-my-4 tw-space-y-4' onSubmit={handleSubmit(onSubmit)}>
                {/* Email */}
                <div>
                  <div
                    className={`tw-flex tw-items-center tw-border  tw-rounded-md tw-overflow-hidden  ${
                      errors?.email?.message ? 'tw-border-red-600' : 'tw-border-cyan-600'
                    }`}
                  >
                    <span>
                      <EnvelopeIcon
                        className={`tw-w-5 tw-mx-1 tw-mt-1 ${
                          errors?.email?.message
                            ? 'tw-text-red-600 tw-animate-bounce'
                            : 'tw-text-cyan-600'
                        }`}
                      />
                    </span>
                    <input
                      type='email'
                      className='tw-w-full placeholder:tw-text-xs tw-px-1 tw-outline-none tw-py-2 tw-text-sm'
                      placeholder='Email'
                      {...register('email', { required: 'Email is required' })}
                    />
                  </div>
                  {errors?.email?.message && (
                    <p className='tw-text-xs tw-text-red-600 tw-font-medium tw-mt-1'>
                      {errors?.email?.message}
                    </p>
                  )}
                </div>

                {/* Password */}
                <div>
                  <div
                    className={`tw-flex tw-items-center tw-border tw-rounded-md tw-overflow-hidden ${
                      errors?.password?.message
                        ? 'tw-border-red-600 '
                        : 'tw-border-cyan-600'
                    }`}
                  >
                    <span>
                      <LockClosedIcon
                        className={`tw-w-5 tw-mx-1 ${
                          errors?.password?.message
                            ? 'tw-text-red-600 tw-animate-bounce'
                            : 'tw-text-cyan-600'
                        }`}
                      />
                    </span>
                    <div className='!tw-relative tw-w-full'>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className='tw-w-full placeholder:tw-text-xs tw-pl-1 tw-pr-10 tw-outline-none tw-py-2 tw-text-sm'
                        placeholder='Password'
                        {...register('password', { required: 'Password is required' })}
                      />
                      <button
                        type='button'
                        className='tw-absolute tw-right-2 tw-top-1/2 -tw-translate-y-1/2 tw-w-5'
                        onClick={() => setShowPassword(prevState => !prevState)}
                      >
                        {showPassword ? (
                          <EyeIcon className='tw-w-5' />
                        ) : (
                          <EyeSlashIcon className='tw-w-5' />
                        )}
                      </button>
                    </div>
                  </div>
                  {errors?.password?.message && (
                    <p className='tw-text-xs tw-text-red-800 tw-font-medium tw-mt-1'>
                      {errors?.password?.message}
                    </p>
                  )}
                </div>

                {/* Login Button */}
                <button
                  type='submit'
                  className='tw-w-64 tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-outline-none tw-bg-green-600 tw-text-white tw-px-2 tw-py-2 tw-text-sm tw-rounded-md'
                  disabled={isSubmitting}
                >
                  <span className='tw-w-52'>
                    {isLoading ? 'Loging' : t('login_to_my_account')}
                  </span>
                  {isLoading && spinner}
                </button>
              </form>

              <button
                className='tw-text-sm tw-text-cyan-600 tw-text-center tw-w-full'
                onClick={() => setIsForgotPasswordModalOpen(true)}
              >
                {t('forgot_password')}?
              </button>

              <hr />
              <div className='tw-font-semibold tw-mb-1'>{t('dont_have_an_account')}?</div>
              <button
                className='tw-border tw-border-green-600 tw-p-2 tw-rounded-md tw-text-sm tw-text-green-600 tw-w-full tw-font-semibold'
                onClick={() => {
                  redirect('/seller-registration');
                }}
              >
                {t('become_a_member_today')}
              </button>
              <button
                className='tw-border tw-border-green-600 tw-p-2 tw-rounded-md tw-text-sm tw-text-green-600 tw-w-full tw-font-semibold tw-mt-4'
                onClick={() => {
                  redirect('/bdm-registration');
                }}
              >
                {t('become_a_business_agent_today')}
              </button>
            </div>
            <div className='tw-hidden sm:tw-block'>
              <img src={twmain} alt='' className='col-image tw-ml-16' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
