// Third party packages
import { Disclosure } from '@headlessui/react';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/solid';

const ProfileAccordion = props => {
  const {
    defaultOpen,
    containerClassName,
    bodyClassName = '',
    panelClassName,
    title,
    titleClassName,
    buttonClassName,
    isVerified,
    disabled,
    children,
    exclamationCircleIconClassName = ''
  } = props;

  const getErrorOrCorrectIcon = isVerified ? (
    <CheckCircleIcon className='tw-text-green-600 tw-w-7' />
  ) : (
    <ExclamationCircleIcon
      className={`tw-text-red-600 tw-w-7 ${exclamationCircleIconClassName}`}
    />
  );

  return (
    <Disclosure as='div' className={containerClassName} defaultOpen={defaultOpen}>
      {({ open }) => (
        <div
          className={`tw-w-full tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-shadow-sm tw-bg-white ${
            disabled ? 'tw-opacity-60 tw-cursor-not-allowed' : ' '
          } ${bodyClassName}`}
        >
          <Disclosure.Button
            disabled={disabled}
            className={`tw-px-3 tw-h-[71px] tw-flex tw-items-center tw-justify-between  ${
              disabled ? '!tw-cursor-not-allowed' : ''
            } ${buttonClassName}`}
          >
            <p
              className={`tw-text-xs md:tw-text-[21px] tw-font-semibold tw-text-[#575858] ${titleClassName}`}
            >
              {title}
            </p>
            <div className='tw-flex tw-items-center tw-space-x-2'>
              {open ? (
                <ChevronDownIcon className='tw-text-[#575858] tw-w-6' />
              ) : (
                <ChevronLeftIcon className='tw-text-[#575858] tw-w-6' />
              )}
              {getErrorOrCorrectIcon}
            </div>
          </Disclosure.Button>

          {!disabled && (
            <Disclosure.Panel className={`tw-pl-10 md:tw-pl-20 ${panelClassName}`}>
              {children}
            </Disclosure.Panel>
          )}
        </div>
      )}
    </Disclosure>
  );
}; // End of ProfileAccordion

export default ProfileAccordion;
